import React from "react";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ScrollToTop from "../Components/ScrollToTop";
import WebDevelopment from "../Components/WebDevelopment";
import { Helmet } from "react-helmet";

export default function WebDevelopmentPage() {
  return (
    <>
      <Helmet>
        <title>
          Hire Best Web development company in Germany | Web Development
          Services in USA
        </title>
        <meta
          name="description"
          content="Hire the Best Web development company in Germany & USA · Web Development Market Stats · Get On-Demand Web Development Services at the Best Price."
        />
      </Helmet>
      <BreadcrumbMain title="Web Development">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Web Development
          </Typography>
        </>
      </BreadcrumbMain>
      <WebDevelopment />
      <ScrollToTop />
    </>
  );
}
