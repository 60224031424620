import * as React from "react";
import TopSectionLocation from "./TopSectionLocation";
import ContactUsForm from "../Contact/ContactUsForm";

export default function LocationComp() {
  return (
    <>
      <TopSectionLocation />
      <ContactUsForm />
    </>
  );
}
