import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import manworkingonlaptopanddrinkingcoffee from "../../../Assets/img/manworkingonlaptopanddrinkingcoffee.svg";

import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";

export default function OpeningTiles() {
  const [fetchdata, setfetchdata] = useState([]);
useEffect(()=>{
  const fetchblog = async()=>{
    await axios.get(`${process.env.REACT_APP_API_URL}/careers`)
    .then(response=>{
      //console.log("get all carrer--->", response)
      setfetchdata(response.data);
    })
    .catch(error=>{
      //console.log("get all carrer error-->", error)
    })
  }
  fetchblog();
}, []);
  return (
    <>
      <div className="opening_tiles">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <h2 className="title">Current Openings</h2>
            <p>Feel fulfilled. Have fun. Help us to shape the future.</p>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={12}>
            <Box
              className="opening_tiles_sec"
              sx={{
                display: "flex",
                justifyContent: "space-around",
                p: 1,
                m: 1,
              }}
            >
              {fetchdata.map((el) => (
              <div className="choose_tile" key={el.id}>
                <div className="icon">
                  <img src={manworkingonlaptopanddrinkingcoffee} alt="img" />
                </div>
                <h2>{el.designation}</h2>
                <p>
                  <span>Location : {el.location}</span> <span>Type : {el.type}</span>
                  {/*<span>Location : Noida</span> <span>Type : Full Time</span>*/}
                </p>
                {/*(`${process.env.REACT_APP_API_URL}/careers`)*/}
                <a href={`${el.url}`}>Apply now</a>  
                {/*https://zurl.to/CGeM?source=CareerSite/1/php-developer*/}
              </div>
              ))}
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
