import React from "react";
import { Fade } from "react-awesome-reveal";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import parse from "html-react-parser";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 450,
  maxHeight: "90%",
  width: "100%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  outline: "none",
  overflow: "auto",
  p: 1,
};

function PortfolioItem(props) {
  const { item } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  ////console.log("portfolio props--->",props)
  return (
    <>
      <Fade>
        <div className="inner-box" onClick={handleOpen}>
          <div className="image-box">
            <figure className="image">
              <img src={`https://node.builtdemo.info/${item.path}`} alt="img" />
            </figure>
            <div className="info-box">
              <ul className="cat-list">
                <li>{parse(item.category)}</li>
              </ul>
              <h4 className="title">{item.name}</h4>
            </div>
          </div>
        </div>
      </Fade>
      <Fade>
        <div className="portfolio_modal">
          <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
              <img
                src={`https://node.builtdemo.info/${item.modal_image_path}`}
                className="portfolio_modal_img"
                alt="img"
              />
            </Box>
          </Modal>
        </div>
      </Fade>
    </>
  );
}
export default PortfolioItem;
