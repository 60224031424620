import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import manworkingonlaptopanddrinkingcoffee from "../../../Assets/img/manworkingonlaptopanddrinkingcoffee.svg";

export default function WDDWdTiles() {
  return (
    <>
      <div className="wdTiles">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <h2 className="subtitle">
              WEB DESIGN & DEVELOPMENT POWERED BY INNOVATION AND VALUES
            </h2>
            <h3 className="title">
              Technology Expertise - A Quest for Excellence!!
            </h3>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={12}>
            <Box
              className="wdTiles_sec"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                m: 1,
              }}
            >
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img
                            src={manworkingonlaptopanddrinkingcoffee}
                            alt="img"
                          />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">HTML / HTML5 / XHTML</Link>
                      </h4>
                      <div className="tile-details">
                        We utilise HTML & HTML5 - Responsive Web Design websites
                        are developed in HTML5. When it comes to organizing and
                        presenting online information, HTML5 is a popular
                        choice. Every single website and web application that we
                        create is built using XHTML markup. On top of
                        HTML5/XHTML, all websites and web applications are
                        created. We are specialists in manipulating HTML5/XHTML
                        technology so that your website/web app has the
                        appearance and feel that you desire..
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img
                            src={manworkingonlaptopanddrinkingcoffee}
                            alt="img"
                          />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">CSS2/3</Link>
                      </h4>
                      <div className="tile-details">
                        Web design relies heavily on CSS (Cascading Style
                        Sheets). On all devices, including mobile phones,
                        tablets, computers, and televisions. It's also possible
                        for us to reduce the amount of web code on each page of
                        your website by using CSS and responsive web design,
                        resulting in a smoother and faster website for all of
                        your online visitors. In contrast to HTML, which focuses
                        on the content and structure of a website, CSS
                        concentrates on presentational aspects such as layout,
                        typeface, and color.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img
                            src={manworkingonlaptopanddrinkingcoffee}
                            alt="img"
                          />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">React JS & Node JS</Link>
                      </h4>
                      <div className="tile-details">
                        Most sophisticated and challenging of the JavaScript
                        languages, React JS is one of them. To quickly create
                        responsive and well-maintained webpages, we utilize the
                        React Javascript framework. Users benefit from reduced
                        load times and a smooth workflow using React. As an
                        alternative, real-time web apps are built using Node JS
                        development. When it comes to JavaScript developers,
                        Node JS is one of the most powerful tools available. It
                        helps them to build highly scalable Apps in a given
                        amount of time and money.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img
                            src={manworkingonlaptopanddrinkingcoffee}
                            alt="img"
                          />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">HTML / HTML5 / XHTML</Link>
                      </h4>
                      <div className="tile-details">
                        We utilise HTML & HTML5 - Responsive Web Design websites
                        are developed in HTML5. When it comes to organizing and
                        presenting online information, HTML5 is a popular
                        choice. Every single website and web application that we
                        create is built using XHTML markup. On top of
                        HTML5/XHTML, all websites and web applications are
                        created. We are specialists in manipulating HTML5/XHTML
                        technology so that your website/web app has the
                        appearance and feel that you desire..
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img
                            src={manworkingonlaptopanddrinkingcoffee}
                            alt="img"
                          />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">CSS2/3</Link>
                      </h4>
                      <div className="tile-details">
                        Web design relies heavily on CSS (Cascading Style
                        Sheets). On all devices, including mobile phones,
                        tablets, computers, and televisions. It's also possible
                        for us to reduce the amount of web code on each page of
                        your website by using CSS and responsive web design,
                        resulting in a smoother and faster website for all of
                        your online visitors. In contrast to HTML, which focuses
                        on the content and structure of a website, CSS
                        concentrates on presentational aspects such as layout,
                        typeface, and color.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img
                            src={manworkingonlaptopanddrinkingcoffee}
                            alt="img"
                          />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">React JS & Node JS</Link>
                      </h4>
                      <div className="tile-details">
                        Most sophisticated and challenging of the JavaScript
                        languages, React JS is one of them. To quickly create
                        responsive and well-maintained webpages, we utilize the
                        React Javascript framework. Users benefit from reduced
                        load times and a smooth workflow using React. As an
                        alternative, real-time web apps are built using Node JS
                        development. When it comes to JavaScript developers,
                        Node JS is one of the most powerful tools available. It
                        helps them to build highly scalable Apps in a given
                        amount of time and money.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
