import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import Codeoptimization from "../../../Assets/img/Codeoptimization.png";
import Php from "../../../Assets/img/Php.png";
import code3 from "../../../Assets/img/code3.png";
import Wordpress from "../../../Assets/img/Wordpress.png";
import Magento from "../../../Assets/img/Magento.png";
import Angular from "../../../Assets/img/Angular.png";
import Shopify from "../../../Assets/img/Shopify.png";
import ReactJs from "../../../Assets/img/React.png";
import NodeJs from "../../../Assets/img/NodeJs.png";

import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

export default function WebAppDevelopmentTiles() {
  return (
    <>
      <div className="WebWdTiles">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <h2 className="subtitle">Web App Development Services</h2>
            <h3 className="title">Our Web Development Services Include</h3>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={12}>
            <Box
              className="wdTiles_sec"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                m: 1,
              }}
            >
              <div className="choose_tile">
                <div className="svg">
                  <svg
                    width="231"
                    height="152"
                    viewBox="0 0 231 152"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.78072 -12.1112C19.612 43.2339 105.113 27.0204 155.24 55.8933C203.058 83.4365 230.526 163.949 283.862 149.791C337.376 135.584 322.766 52.5694 349.793 4.2466C374.897 -40.6363 443.738 -67.7063 433.723 -118.148C423.719 -168.534 354.03 -173.449 309.446 -198.966C266.851 -223.345 230.519 -272.335 182.563 -261.897C134.283 -251.388 120.983 -191.765 92.1193 -151.662C58.5774 -105.06 -13.9255 -67.045 2.78072 -12.1112Z"
                      fill="#BDBDBD"
                    />
                  </svg>
                </div>
                <div className="icon">
                  <img src={Codeoptimization} alt="img" />
                </div>
                <h2>Codeigniter Framework</h2>
                <p>
                  Codeigniter has been the buzz of the week for a variety of
                  reasons, but the most important one is its agility, which
                  makes it a popular choice. When it comes to Open Source
                  programming projects, it's hard to beat Codeigniter. When
                  combined with a unique approach, it provides ideal answers to
                  the needs. Is myDevITSolutions a leader in the Codeigniter
                  development industry? The Codeigniter web service encourages
                  users to take advantage of the framework to the maximum extent
                  possible.
                </p>
                <Link to="/portfolio" className="link">
                  <ArrowRightAltIcon />
                </Link>
              </div>
              <div className="choose_tile">
                <div className="svg">
                  <svg
                    width="231"
                    height="152"
                    viewBox="0 0 231 152"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.78072 -12.1112C19.612 43.2339 105.113 27.0204 155.24 55.8933C203.058 83.4365 230.526 163.949 283.862 149.791C337.376 135.584 322.766 52.5694 349.793 4.2466C374.897 -40.6363 443.738 -67.7063 433.723 -118.148C423.719 -168.534 354.03 -173.449 309.446 -198.966C266.851 -223.345 230.519 -272.335 182.563 -261.897C134.283 -251.388 120.983 -191.765 92.1193 -151.662C58.5774 -105.06 -13.9255 -67.045 2.78072 -12.1112Z"
                      fill="#BDBDBD"
                    />
                  </svg>
                </div>
                <div className="icon">
                  <img src={Php} alt="img" />
                </div>
                <h2>PHP Development</h2>
                <p>
                  Converting an existing website/web app into an engaging
                  experience is the difficult part. Our PHP solutions are
                  utilized by some of the biggest brands in the business sector
                  since we are extremely competent. We have a large staff of
                  professionals that are well-versed in PHP programming. To
                  manage projects of varying sizes and complexity, our PHP
                  developers use their knowledge and expertise. There's no doubt
                  that we offer the best PHP programming services available.
                </p>
                <Link to="/portfolio" className="link">
                  <ArrowRightAltIcon />
                </Link>
              </div>
              <div className="choose_tile">
                <div className="svg">
                  <svg
                    width="231"
                    height="152"
                    viewBox="0 0 231 152"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.78072 -12.1112C19.612 43.2339 105.113 27.0204 155.24 55.8933C203.058 83.4365 230.526 163.949 283.862 149.791C337.376 135.584 322.766 52.5694 349.793 4.2466C374.897 -40.6363 443.738 -67.7063 433.723 -118.148C423.719 -168.534 354.03 -173.449 309.446 -198.966C266.851 -223.345 230.519 -272.335 182.563 -261.897C134.283 -251.388 120.983 -191.765 92.1193 -151.662C58.5774 -105.06 -13.9255 -67.045 2.78072 -12.1112Z"
                      fill="#BDBDBD"
                    />
                  </svg>
                </div>
                <div className="icon">
                  <img src={code3} alt="img" />
                </div>
                <h2>Laravel Framework</h2>
                <p>
                  This PHP framework (Laravel) is considered to be one of the
                  strongest and cleanest frameworks available, which makes it
                  easy to create high-quality web applications with the least
                  amount of effort feasible. Because it eliminates rewriting of
                  code, Web development is easier and faster as a result of this
                  technology." myDevITSolutions is one of the top Laravel web
                  development firms for solutions to your project, so don't look
                  elsewhere. Because our Laravel engineers have extensive
                  experience with the Laravel Framework, they are well-versed in
                  all elements of the framework.
                </p>
                <Link to="/portfolio" className="link">
                  <ArrowRightAltIcon />
                </Link>
              </div>
              <div className="choose_tile">
                <div className="svg">
                  <svg
                    width="231"
                    height="152"
                    viewBox="0 0 231 152"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.78072 -12.1112C19.612 43.2339 105.113 27.0204 155.24 55.8933C203.058 83.4365 230.526 163.949 283.862 149.791C337.376 135.584 322.766 52.5694 349.793 4.2466C374.897 -40.6363 443.738 -67.7063 433.723 -118.148C423.719 -168.534 354.03 -173.449 309.446 -198.966C266.851 -223.345 230.519 -272.335 182.563 -261.897C134.283 -251.388 120.983 -191.765 92.1193 -151.662C58.5774 -105.06 -13.9255 -67.045 2.78072 -12.1112Z"
                      fill="#BDBDBD"
                    />
                  </svg>
                </div>
                <div className="icon">
                  <img src={Wordpress} alt="img" />
                </div>
                <h2>WordPress</h2>
                <p>
                  As of now, WordPress is the world's most popular and most
                  widely used content management system. There are a number of
                  reasons why WordPress (WP) is a good choice. WP, on the other
                  hand, is a simple platform that is easy to adopt and
                  customised to your liking. Content management on a WordPress
                  CMS does not require knowledge of web programming languages.
                </p>
                <Link to="/portfolio" className="link">
                  <ArrowRightAltIcon />
                </Link>
              </div>
              <div className="choose_tile">
                <div className="svg">
                  <svg
                    width="231"
                    height="152"
                    viewBox="0 0 231 152"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.78072 -12.1112C19.612 43.2339 105.113 27.0204 155.24 55.8933C203.058 83.4365 230.526 163.949 283.862 149.791C337.376 135.584 322.766 52.5694 349.793 4.2466C374.897 -40.6363 443.738 -67.7063 433.723 -118.148C423.719 -168.534 354.03 -173.449 309.446 -198.966C266.851 -223.345 230.519 -272.335 182.563 -261.897C134.283 -251.388 120.983 -191.765 92.1193 -151.662C58.5774 -105.06 -13.9255 -67.045 2.78072 -12.1112Z"
                      fill="#BDBDBD"
                    />
                  </svg>
                </div>
                <div className="icon">
                  <img src={Magento} alt="img" />
                </div>
                <h2>Magento</h2>
                <p>
                  Ecommerce solutions that require serious consideration should
                  consider using Magento as the best web tool on the market
                  right now. From one site, you can sell on mobile devices as
                  well as in-store and online. Great reporting capabilities are
                  included as well, which may help you figure out what your
                  consumers are doing when they're on your site and which goods
                  sell better. Above all else, it's built on top of a scalable
                  architecture that offers the infrastructure needed to support
                  your business's development.
                </p>
                <Link to="/portfolio" className="link">
                  <ArrowRightAltIcon />
                </Link>
              </div>
              <div className="choose_tile">
                <div className="svg">
                  <svg
                    width="231"
                    height="152"
                    viewBox="0 0 231 152"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.78072 -12.1112C19.612 43.2339 105.113 27.0204 155.24 55.8933C203.058 83.4365 230.526 163.949 283.862 149.791C337.376 135.584 322.766 52.5694 349.793 4.2466C374.897 -40.6363 443.738 -67.7063 433.723 -118.148C423.719 -168.534 354.03 -173.449 309.446 -198.966C266.851 -223.345 230.519 -272.335 182.563 -261.897C134.283 -251.388 120.983 -191.765 92.1193 -151.662C58.5774 -105.06 -13.9255 -67.045 2.78072 -12.1112Z"
                      fill="#BDBDBD"
                    />
                  </svg>
                </div>
                <div className="icon">
                  <img src={Angular} alt="img" />
                </div>
                <h2>Angular JS</h2>
                <p>
                  With AngularJS, HTML is broadened and enhanced. By just adding
                  an element of AngularJS into your HTML specifications, a
                  delicious meal is ready to be served. As a static document
                  declaration language, HTML excels. As a dynamic view
                  declaration language, HTML falls short. In case you're looking
                  for a Javascript framework development company, our AngularJS
                  engineers can help.
                </p>
                <Link to="/portfolio" className="link">
                  <ArrowRightAltIcon />
                </Link>
              </div>
              <div className="choose_tile">
                <div className="svg">
                  <svg
                    width="231"
                    height="152"
                    viewBox="0 0 231 152"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.78072 -12.1112C19.612 43.2339 105.113 27.0204 155.24 55.8933C203.058 83.4365 230.526 163.949 283.862 149.791C337.376 135.584 322.766 52.5694 349.793 4.2466C374.897 -40.6363 443.738 -67.7063 433.723 -118.148C423.719 -168.534 354.03 -173.449 309.446 -198.966C266.851 -223.345 230.519 -272.335 182.563 -261.897C134.283 -251.388 120.983 -191.765 92.1193 -151.662C58.5774 -105.06 -13.9255 -67.045 2.78072 -12.1112Z"
                      fill="#BDBDBD"
                    />
                  </svg>
                </div>
                <div className="icon">
                  <img src={Shopify} alt="img" />
                </div>
                <h2>Shopify</h2>
                <p>
                  Web-based Ecommerce programme Shopify makes it easy to set up
                  and maintain an online store, making it ideal for any size
                  business. In addition, Shopify includes inventory and order
                  management for products, as well as content management and
                  payment gateway integration for e-commerce sites. There are
                  several reasons to choose Shopify as a shopping cart solution
                  for your online store. As an e-commerce platform, Shopify
                  certainly has made great strides.
                </p>
                <Link to="/portfolio" className="link">
                  <ArrowRightAltIcon />
                </Link>
              </div>
              <div className="choose_tile">
                <div className="svg">
                  <svg
                    width="231"
                    height="152"
                    viewBox="0 0 231 152"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.78072 -12.1112C19.612 43.2339 105.113 27.0204 155.24 55.8933C203.058 83.4365 230.526 163.949 283.862 149.791C337.376 135.584 322.766 52.5694 349.793 4.2466C374.897 -40.6363 443.738 -67.7063 433.723 -118.148C423.719 -168.534 354.03 -173.449 309.446 -198.966C266.851 -223.345 230.519 -272.335 182.563 -261.897C134.283 -251.388 120.983 -191.765 92.1193 -151.662C58.5774 -105.06 -13.9255 -67.045 2.78072 -12.1112Z"
                      fill="#BDBDBD"
                    />
                  </svg>
                </div>
                <div className="icon">
                  <img src={ReactJs} alt="img" />
                </div>
                <h2>React JS</h2>
                <p>
                  With React Js Development, we get many modules and components
                  and can add new controls in a fraction of the time. Due to the
                  ease with which a distribution model may be developed, the
                  product is able to produce effective outcomes over time.
                </p>
                <Link to="/portfolio" className="link">
                  <ArrowRightAltIcon />
                </Link>
              </div>
              <div className="choose_tile">
                <div className="svg">
                  <svg
                    width="231"
                    height="152"
                    viewBox="0 0 231 152"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.78072 -12.1112C19.612 43.2339 105.113 27.0204 155.24 55.8933C203.058 83.4365 230.526 163.949 283.862 149.791C337.376 135.584 322.766 52.5694 349.793 4.2466C374.897 -40.6363 443.738 -67.7063 433.723 -118.148C423.719 -168.534 354.03 -173.449 309.446 -198.966C266.851 -223.345 230.519 -272.335 182.563 -261.897C134.283 -251.388 120.983 -191.765 92.1193 -151.662C58.5774 -105.06 -13.9255 -67.045 2.78072 -12.1112Z"
                      fill="#BDBDBD"
                    />
                  </svg>
                </div>
                <div className="icon">
                  <img src={NodeJs} alt="img" />
                </div>
                <h2>Node JS</h2>
                <p>
                  With its sophisticated improved standards, Node JS Development
                  opens up new opportunities for organisations. As a result, it
                  has become a popular structure among organisations looking for
                  quick real-time applications, traffic-intensive apps,
                  networked applications, or even mobile applications that need
                  to communicate with the platform API and even database, among
                  others. To provide a JavaScript-based application development
                  platform that is current, dependable, and stable.
                </p>
                <Link to="/portfolio" className="link">
                  <ArrowRightAltIcon />
                </Link>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
