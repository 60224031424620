import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import wd1 from "../../../Assets/img/wd1.png";
import "./index.scss";

export default function WdTopSection() {
  return (
    <>
      <section className="web_design">
        <div className="background-overlay"></div>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={6}>
            <div className="wma_img">
              <img src={wd1} alt="Web Design" />
            </div>
          </Grid>

          <Grid xs={12} sm={6}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h2 className="title ">
                  <span className="title-part1 ">Web design company</span>
                </h2>
                <p className="mt-30">
                  Webnotics Solutions is a leading web design company in Germany
                  & USA giving best website design services to across the world.
                  As an imaginative website design company, We do web designing,
                  website re-planning, email template design , Website
                  Maintenance to various agency the face lift that they need and
                  helping them attract as well as retain online website traffic.
                </p>
                <p>
                  Our Webnotics Solutions team of famously professional web
                  designers works in in coordination with the clients to
                  completely comprehend their requirements and objectives to
                  hand-create the site for business interests to stand apart
                  from the crowd.
                </p>
                <p>
                  Each business knows about the power of a website and the way
                  appropriately it can talk immediately with its client. Our
                  team focuses on developing high-stop, specific and attractive
                  designs proposing brand new tendencies and good practices Our
                  team of expert web designers works in a consultative way and
                  includes into making totally helpful and shocking website.
                </p>

                <a
                  href="https://join.skype.com/invite/Ybh1FyV4SeQb"
                  target="_blank"
                  rel="noreferrer"
                  className="c_link"
                >
                  <Button variant="contained" className="btn-theme-colored">
                    <span>get in touch</span>
                  </Button>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
