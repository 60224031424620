import ScrollToTop from "../Components/ScrollToTop";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import LocationComp from "../Components/LocationComp";
import { Grid } from "@mui/material";

export default function Locations() {
  return (
    <>
      <BreadcrumbMain title="Locations">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Locations
          </Typography>
        </>
      </BreadcrumbMain>
      <Grid
        container
        spacing={0}
        className="box-container"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={12}>
          <LocationComp />
        </Grid>
      </Grid>
      <iframe
        width="530"
        height="500"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.111025358608!2d77.37008061502574!3d28.626434391107786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cefc63611bd13%3A0x37c3a4df063fa487!2sWebnotics%20Solutions!5e0!3m2!1sen!2sin!4v1672393818726!5m2!1sen!2sin"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        loading="lazy"
        frameBorder="0"
        style={{ width: "100%" }}
      ></iframe>
      <ScrollToTop />
    </>
  );
}
