import React from "react";
import { Grid } from "@mui/material";
import "./index.scss";
import ContactUsForm from "./ContactUsForm";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

function Contact() {
  return (
    <div className="contact_us">
      <Grid
        container
        spacing={0}
        className="box-container"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={12} sm={12} md={7} lg={8}>
          <ContactUsForm />
        </Grid>
        <Grid xs={12} sm={12} md={5} lg={4}>
          <div className="contact-icon-box">
            <div className="contact-box-wrapper">
              <div className="contact-wrapper">
                <span className="contact-circled">
                  <LocalPhoneOutlinedIcon />
                </span>
              </div>
              <div className="contact-text">
                <h4 className="contact-box-title ">Phone Number </h4>
                <div className="contact-content">
                  <p>
                    <span>Phone : </span>
                    <a href="tel:+1 347-467-1222">+1 347-467-1222</a>
                    <br />
                    &nbsp;
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-icon-box">
            <div className="contact-box-wrapper">
              <div className="contact-wrapper">
                <span className="contact-circled">
                  <NearMeOutlinedIcon />
                </span>
              </div>
              <div className="contact-text">
                <h4 className="contact-box-title ">Headquarters</h4>
                <div className="contact-content">
                  <p>
                    <span>Address : </span>131 West 36th Street #1,
                    <br />
                    New York US
                  </p>
                  <p>
                    <span>Address : </span>Lackerbauerstraße
                    <br />
                    House no.- 3<br />
                    Munich, BY 81241
                    <br />
                    Germany
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-icon-box">
            <div className="contact-box-wrapper">
              <div className="contact-wrapper">
                <span className="contact-circled">
                  <EmailOutlinedIcon />
                </span>
              </div>
              <div className="contact-text">
                <h4 className="contact-box-title ">Email Address</h4>
                <div className="contact-content">
                  <p>
                    <span>Email : </span>hello@webnotics.solutions <br />
                    &nbsp;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Contact;
