import Grid from "@mui/material/Grid";
import industrialIOT from "../../../Assets/img/industrialIOT.jpg";
import "./index.scss";

export default function AboutIiot() {
  return (
    <>
      <section className="AboutWebnotics">
        <div className="background-overlay"></div>
        <Grid
          container
          spacing={0}
          className="box-container"
          justifyContent="center"
          alignItems="center"
        >
          <Grid xs={12} sm={6}>
            <div className="wma_img">
              <img src={industrialIOT} alt="Webnotics Iiot" />
            </div>
          </Grid>

          <Grid xs={12} sm={6}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h2 className="subtitle ">INDUSTRIAL IOT</h2>
                <h3 className="title ">
                  <span className="title-part1 ">
                    Transforming Data into Blockbuster Business Success
                  </span>
                </h3>
                <p className="mt-30">
                  The Industrial Internet of Things (IIoT) empowers
                  organizations to harness their data, revealing insights that
                  drive substantial business value. At the heart of Industry
                  4.0, IIoT is not just about optimizing current operations;
                  it's about evolving revolutionary service models.
                </p>
                <p>
                  Leveraging Webnotics’s robust software engineering expertise
                  and profound industrial knowledge, enterprises are
                  fast-tracked towards transformative success. With industrial
                  data accessible anytime, anywhere, and in any fidelity, teams
                  gain critical insights. These insights are more than just
                  information; they are powerful tools enabling them to reinvent
                  their work processes. This transformation leads to marked
                  improvements in productivity, reliability, and financial
                  health, setting a new standard for industrial efficiency and
                  innovation.
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
      <section className="AboutWebnotics key_diff">
        <Grid
          container
          spacing={0}
          className="box-container"
          justifyContent="center"
          alignItems="center"
        >
          <Grid xs={12} sm={12}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h3 className="title">
                  <span className="title-part1">Key Differentiators</span>
                </h3>
              </div>
              <div className="title-wrapper box_dsn">
                <h3 className="title ">
                  <span className="title-part1 ">
                    Integrated IT/OT Expertise: Your Comprehensive Turnkey
                    Solution
                  </span>
                </h3>
                <p className="mt-30">
                  Our team, comprised of industrial specialists, seasoned
                  software engineers, astute data scientists, and experienced
                  consultants, excels in bridging the gap between physical
                  assets and digital insights. We offer all-encompassing,
                  end-to-end solutions by collaborating closely with top-tier
                  global software and hardware partners. Our approach ensures
                  seamless integration and delivery of sophisticated IT/OT
                  capabilities, tailored to meet your unique business needs.
                </p>
              </div>
              <div className="title-wrapper box_dsn">
                <h3 className="title">
                  <span className="title-part1 ">
                    Measurable Outcomes and ROI Through a Value-Driven Approach
                  </span>
                </h3>
                <p className="mt-30">
                  Our strategy focuses on empowering enterprises to address
                  intricate industrial challenges effectively. By adopting a
                  consulting-led methodology, infused with IIoT system thinking
                  and rapid prototyping, we facilitate a consistent flow of
                  rapid successes. This approach is carefully designed to yield
                  tangible results while maintaining controlled investments,
                  ultimately leading to significant return on investment and
                  measurable benefits for your business.
                </p>
              </div>
              <div className="title-wrapper box_dsn">
                <h3 className="title">
                  <span className="title-part1 ">
                    Platform-Driven IPs and Accelerators for Rapid Market Entry
                  </span>
                </h3>
                <p className="mt-30">
                  At Webnotics' Industrial Internet of Things (IIoT) Center of
                  Excellence (CoE), we've dedicated resources to develop
                  specialized IPs and Accelerators. These are crafted on leading
                  platforms, focusing on specific use cases to facilitate a
                  swift journey to market while supporting ambitious business
                  objectives. By integrating advanced analytics and artificial
                  intelligence, these tools not only enhance operational
                  predictability but also open doors to new possibilities.
                  <p>
                    The outcome is a significant enhancement in various areas:
                    problem-solving becomes more efficient, productivity sees a
                    noticeable boost, resource planning is optimized, customer
                    satisfaction levels rise, and there's a heightened agility
                    in responding to market fluctuations.
                  </p>
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
