import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import internationallove from "../../../Assets/img/internationallove.svg";
import Dashboard from "../../../Assets/img/Dashboard.svg";
import MobileApplication from "../../../Assets/img/MobileApplication.svg";
import { Box } from "@mui/material";

export default function WdadCapabilityTiles() {
  return (
    <>
      <div className="WdadCapabilityTiles">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <h2 className="subtitle">
              Webnotics Solutions provides a magnificent interface with a robust
              backend that can be used with or without a CMS, allowing the world
              to experience your website's class.
            </h2>
            <h3 className="title">Web Design & Development</h3>
          </Grid>
        </Grid>

        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={12}>
            <Box
              className="WdadCapabilityTiles_sec"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 0,
                m: 0,
              }}
            >
              <div className="choose_tile">
                <div className="icon">
                  <img src={internationallove} alt="img" />
                </div>
                <h2 className="title service-title">Web Design</h2>
                <p>
                  A website isn't merely a collection of online pages. An
                  eye-catching website has outstanding design aspects that set
                  it apart from the competition.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={Dashboard} alt="img" />
                </div>
                <h2 className="title service-title">Custom Web Development</h2>
                <p>
                  With our custom-made website, you may reach your business
                  goals with proper design layout, functionalities, and
                  features, and you will undoubtedly succeed!!
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={MobileApplication} alt="img" />
                </div>
                <h2 className="title service-title">CMS Design &Development</h2>
                <p>
                  With CMS web development, you may quickly administer your
                  website using an admin panel. Without much technological
                  experience, you can update your website more frequently.
                </p>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
