import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "./index.scss";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import quote from "../../../Assets/img/quote-icon.png";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useEffect, useState } from "react";
import axios from "axios";

export default function TestimonialsItems() {
  const [fetchdata, setfetchdata] = useState([]);
useEffect(()=>{
  const fetchTestimonial = async()=>{
    await axios.get(`${process.env.REACT_APP_API_URL}/testimonial`)
    .then(response=>{
      ////console.log("get all testimonial--->", response)
      setfetchdata(response.data);
    })
    .catch(error=>{
      //console.log("get all testimonial error-->", error)
    })
  }
  fetchTestimonial();
}, []);
  return (
    <div className="Testimonials">
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
          delay: 3000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        loop
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        {fetchdata.map((el) => (
        <SwiperSlide key={el.id}>
          <div className="testimonial">
            <div className="top_section">
              <div className="logo">
                <img src={`https://node.builtdemo.info/${el.image_path}`} alt="testimonial" />
              </div>
              <div className="info">
                <div>
                  <h2>{el.client_name}</h2>
                  <p className="position">{el.client_post}</p>
                </div>
                <span className="icon-quote">
                  <img src={quote} alt="testimonial" />
                </span>
              </div>
            </div>
            <p className="description">
              {el.description}
            </p>
            <div className="rating">
            {[1, 2, 3, 4, 5].map((ek) => {
                    return ( 
              <div className="star-rating" key={ek}>
              {ek <= el.rating ? <StarIcon />:<StarBorderIcon />}
                {/*<StarIcon /> <StarIcon /> <StarIcon /> <StarBorderIcon />{" "}
                <StarBorderIcon />*/}
                
              </div>
              );
            })}
            </div>
          </div>
        </SwiperSlide>
        ))}

      </Swiper>
    </div>
  );
}
