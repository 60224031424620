import * as React from "react";
import SeoTopSection from "./SeoTopSection";
import ChatCta from "../ChatCta";
import LogoCarousel from "../HomeComp/LogoCarousel";

export default function SeoOptimization() {
  return (
    <>
      <SeoTopSection />
      <ChatCta />
      <LogoCarousel />
    </>
  );
}
