import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import choose from "../../../Assets/img/choose1.png";
import "./index.scss";

export default function WhyChoose() {
  return (
    <>
      <section className="WhyChoose">
        <div className="background-overlay"></div>
        <Grid
          container
          spacing={0}
          className="box-container"
          justifyContent="center"
          alignItems="center"
        >
          <Grid xs={12} sm={6}>
            <div className="wma_img">
              <img src={choose} alt="why choose us" />
            </div>
          </Grid>

          <Grid xs={12} sm={6}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h2 className="subtitle ">Choose Us</h2>
                <h3 className="title ">
                  <span className="title-part1 ">Why choose Webnotics?</span>
                </h3>
                <p className="mt-30">
                  Webnotics Solutions is an Offshore IT & Software company
                  offers End-to-End Web Application & Mobile Apps Solutions
                  targeting to diverse Business Needs. The Company was founded
                  in 2008 with a vision to provide Web Solutions to Startups &
                  SMEs by using latest technologies. The company was started
                  with 4 people and in span of few years has grown exponentially
                  to 120+ employee base comprising of resources with diverse
                  skill sets.
                </p>
                <p>
                  The company is driven by a sustainable vision and values, and
                  our services are built on principles such as passion,
                  commitment, smart work and integrity. With customer-centric
                  and progressive approach we are committed to provide
                  cutting-edge solutions to our customers with high quality
                  assurance. Being an Information Technology Enterprise, we
                  believe that technology should be accessible to people across
                  the different levels of society and therefore, we keep
                  developing technological advanced products to fulfill both
                  individual and enterprise needs keeping the quality standards
                  at the highest level.
                </p>

                <Link to="/our-capabilities">
                  <Button variant="contained" className="btn-theme-colored">
                    <span>View all service</span>
                  </Button>
                </Link>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
