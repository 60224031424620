import * as React from "react";
import "./index.scss";
import LogoCarousel from "../HomeComp/LogoCarousel";
import Stats from "../AboutUs/Stats";
import Testimonials from "../HomeComp/Testimonials";
import ChatCta from "../ChatCta";
import CmsTopSection from "./CmsTopSection";
import CmsTiles from "./CmsTiles";
import Methodology from "../WebDesign/Methodology";

export default function CmDesignandDevelopment() {
  return (
    <div className="CmDesignandDevelopment">
      <CmsTopSection />
      <CmsTiles />
      <Stats />
      <Testimonials />
      <Methodology />
      <ChatCta />
      <LogoCarousel />
    </div>
  );
}
