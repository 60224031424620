import React from "react";
import "./index.scss";
import AboutWebnotics from "./WhyChoose";
import ChooseTiles from "./ChooseTiles";

function WhyChooseUs() {
  return (
    <>
      <AboutWebnotics />
      <ChooseTiles />
    </>
  );
}

export default WhyChooseUs;
