import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";

import "./logocarousel.scss";
import { useEffect, useState } from "react";
import axios from "axios";

export default function LogoCarousel() {
  const [fetchlogo, setFetchLogo] = useState([]);
  useEffect(()=>{
    const Logodata= async()=>{
      await axios.get(`${process.env.REACT_APP_API_URL}/logo-carousel`)
      .then(response=>{
        ////console.log("logo carousel detail-->",response)
setFetchLogo(response.data)
      }).catch(error=>{
        //console.log(error)
      })
    }
    Logodata();
  },[])
  return (
    <div className="logoCarousel">
      <Swiper
        modules={[Autoplay]}
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
          delay: 2000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        loop
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
        className="mySwiper"
      >
        {fetchlogo.map((logo)=>(
        <SwiperSlide key={logo.id}>
          <img src={`https://node.builtdemo.info/${logo.path}`} alt="logo" />
        </SwiperSlide>
        ))}
        {/*<SwiperSlide>
          <img src={logo2} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo3} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo4} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo5} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo1} alt="logo" />
        </SwiperSlide>*/}
      </Swiper>
    </div>
  );
}
