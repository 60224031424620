import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import CountUp from "react-countup";

export default function IiotStats() {
  return (
    <>
      <div className="IiotStats">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <div className="stats__box">
              <ul>
                <li>
                  <span className="counts">
                    {" "}
                    <CountUp start={0} end={95} duration={3} />%
                  </span>
                  <span>IIOT Platform Uptime</span>
                </li>
                <li className="blue">
                  <span className="counts">
                    {" "}
                    <CountUp start={0} end={97} duration={4} />%
                  </span>
                  <span>Application Uptime</span>
                </li>
                <li>
                  <span className="counts">
                    {" "}
                    <CountUp start={0} end={20} duration={5} />%
                  </span>
                  <span>Increase in Worker Productivity</span>
                </li>
                <li>
                  <span className="counts">
                    {" "}
                    <CountUp start={0} end={70} duration={6} />%
                  </span>
                  <span>Faster Issue Backtracking</span>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
