import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
//import internationallove from "../../../Assets/img/internationallove.svg";
//import Dashboard from "../../../Assets/img/Dashboard.svg";
//import MobileApplication from "../../../Assets/img/MobileApplication.svg";
//import MarketingTeam from "../../../Assets/img/MarketingTeam.svg";
//import customersupport from "../../../Assets/img/customersupport.svg";
//import PremiumHosting from "../../../Assets/img/PremiumHosting.svg";
import parse from 'html-react-parser';
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

export default function OurCapabilityTiles() {
  const [fetchdata, setfetchdata] = useState([]);
useEffect(()=>{
  const fetchservices = async()=>{
    await axios.get(`${process.env.REACT_APP_API_URL}/services`)
    .then(response=>{
      //console.log("get all services--->", response)
      setfetchdata(response.data);
    })
    .catch(error=>{
      //console.log("get all services error-->", error)
    })
  }
  fetchservices();
}, []);
  return (
    <>
      <div className="OurCapabilityTiles">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <h2 className="subtitle">
              Get everything you'll ever need to upscale your business.
            </h2>
            <h3 className="title">
              Building websites that work wonders for you. Take a Tour!!
            </h3>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={12}>
            <Box
              className="wdTiles_sec"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                m: 1,
              }}
            >
              {fetchdata.map((el) => (
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={`https://node.builtdemo.info/${el.image_path}`} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="#">{el.title}</Link>
                      </h4>
                      <div className="tile-details">
                        {parse(el.description)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              ))}
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
