import React from "react";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ScrollToTop from "../Components/ScrollToTop";
import CmDesignandDevelopment from "../Components/CmDesignandDevelopment";
import { Helmet } from "react-helmet";

export default function CmsDesignandDevelopmentPage() {
  return (
    <>
      <Helmet>
        <title>CMS Design and Development Agency Germany</title>
        <meta
          name="description"
          content="Best CMS design and developemnt services in USA,  Find & hire the best CMS development agencies in Germany. Here are the best ones listed just for you based on verified client reviews."
        />
      </Helmet>
      <BreadcrumbMain title="CMS Design & Development">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Cms Design & Development
          </Typography>
        </>
      </BreadcrumbMain>
      <CmDesignandDevelopment />
      <ScrollToTop />
    </>
  );
}
