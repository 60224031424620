import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import sap1 from "../../../Assets/img/sap1.svg";


import { Box } from "@mui/material";

export default function IiotTiles() {
  return (
    <>
      <div className="IiotTiles">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <h3 className="title">Our IOT Services</h3>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={12}>
            <Box
              className="IiotTiles_sec"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 0,
                m: 0,
              }}
            >
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>IIoT Consulting Services</h2>
                <p>
                  Advising businesses on how to integrate IIoT into their
                  operations, including strategy development and roadmap
                  planning.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Sensor and Device Integration</h2>
                <p>
                  Implementing and integrating various sensors and devices into
                  industrial equipment to collect and transmit data.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Data Analytics and Management</h2>
                <p>
                  Analyzing the collected data to gain insights into operations,
                  maintenance needs, and potential improvements.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Predictive Maintenance Solutions</h2>
                <p>
                  Using data analytics to predict equipment failures before they
                  occur, thereby reducing downtime and maintenance costs.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Asset Tracking and Management</h2>
                <p>
                  Solutions for tracking the location, condition, and usage of
                  assets in real-time.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Supply Chain Optimization</h2>
                <p>
                  Leveraging IIoT to enhance supply chain visibility and
                  efficiency.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Energy Management Solutions</h2>
                <p>
                  Systems for monitoring and managing energy consumption to
                  increase efficiency and reduce costs.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Custom Software Development</h2>
                <p>
                  Developing tailored software solutions for specific IIoT
                  applications.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Cloud Computing and Storage Solutions</h2>
                <p>
                  Providing cloud infrastructure for data storage, processing,
                  and analytics.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Cybersecurity Solutions</h2>
                <p>
                  Ensuring the security of IIoT devices and data from cyber
                  threats.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Edge Computing Solutions</h2>
                <p>
                  Processing data locally on devices or nearby computing
                  platforms to reduce latency and reliance on cloud
                  connectivity.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Training and Support Services</h2>
                <p>
                  Offering training for staff to effectively use IIoT
                  technologies and ongoing support for maintenance and
                  troubleshooting.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Machine Learning and AI Integration</h2>
                <p>
                  Implementing AI algorithms for advanced data analysis and
                  decision-making processes.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>User Interface and Experience Design</h2>
                <p>
                  Creating user-friendly interfaces for managing and interacting
                  with IIoT systems.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Remote Monitoring and Control</h2>
                <p>
                  Enabling the monitoring and control of industrial processes
                  from remote locations.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Compliance and Standards Advisory</h2>
                <p>
                  Helping businesses comply with industry standards and
                  regulations related to IIoT.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Integration with Existing Systems</h2>
                <p>
                  Ensuring that IIoT solutions seamlessly integrate with a
                  company’s existing IT and OT systems.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Digital Twin Technology</h2>
                <p>
                  Creating virtual representations of physical assets for
                  simulation and analysis purposes. 
                </p>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
