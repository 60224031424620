import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import Laptop from "../../../Assets/img/Laptop.png";
import Responsive from "../../../Assets/img/Responsive.png";
import Settingmobile from "../../../Assets/img/Settingmobile.png";
import Setting from "../../../Assets/img/Setting.png";
import Code2 from "../../../Assets/img/Code2.png";
import Multicolor from "../../../Assets/img/Multicolor.png";

import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

export default function CmsTiles() {
  return (
    <>
      <div className="CmsTiles">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <h2 className="subtitle">CMS DESIGN & DEVELOPMENT</h2>
            <h3 className="title">Our CMS Design & Development Includes</h3>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={12}>
            <Box
              className="wdTiles_sec"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                m: 1,
              }}
            >
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={Laptop} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">CMS Refers</Link>
                      </h4>
                      <div className="tile-details">
                        Are you the type of person who loves to update the
                        website’s material as and when necessary? We can say
                        that you were created for a Content Management System.
                      </div>

                      <Link to="/portfolio" className="read-more">
                        <ArrowRightAltIcon /> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={Responsive} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">CMS Flexibility</Link>
                      </h4>
                      <div className="tile-details">
                        When you use a CMS, you may edit or enhance the
                        website's content at will, without having to touch or
                        smash the website's back end.
                      </div>

                      <Link to="/portfolio" className="read-more">
                        <ArrowRightAltIcon /> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={Settingmobile} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">CMS Helps</Link>
                      </h4>
                      <div className="tile-details">
                        Create, edit, and modify content in a CMS online
                        application. In reality, it may also be used to add or
                        remove pictures from a document.
                      </div>

                      <Link to="/portfolio" className="read-more">
                        <ArrowRightAltIcon /> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={Setting} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">No need of an expert</Link>
                      </h4>
                      <div className="tile-details">
                        With a simple user interface, you can edit your website
                        without any coding experience.
                      </div>

                      <Link to="/portfolio" className="read-more">
                        <ArrowRightAltIcon /> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={Code2} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">CMS Variations</Link>
                      </h4>
                      <div className="tile-details">
                        WordPress, MAGENTO, Woo-Commerce, Drupal, and Joomla are
                        some of the top CMS for website creation that we
                        utilize. Each of these CMS has its own set of features
                        that make your content more efficient and more popular.
                      </div>

                      <Link to="/portfolio" className="read-more">
                        <ArrowRightAltIcon /> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={Multicolor} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">Creative Web Designs</Link>
                      </h4>
                      <div className="tile-details">
                        Custom/tailored-made solutions, as we all know, are
                        always on the expensive side. A cost-effective solution
                        is offered by CMS to help prevent this confusing
                        scenario. A new revenue stream is created when people
                        are encouraged to migrate their business models online.
                      </div>

                      <Link to="/portfolio" className="read-more">
                        <ArrowRightAltIcon /> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
