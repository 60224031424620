import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import about from "../../../Assets/img/about.png";
import "./index.scss";

export default function AboutUs() {
  return (
    <>
      <section className="about_us">
        <div className="background-overlay"></div>
        <Grid
          container
          spacing={0}
          className="box-container"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid xs={12} sm={6}>
            <div className="wma_img">
              <img src={about} alt="web and mobile services" />
            </div>
          </Grid>

          <Grid xs={12} sm={6}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h2 className="subtitle ">About Us</h2>
                <h3 className="title ">
                  <span className="title-part1 ">
                    We are a small fun loving team.
                  </span>
                </h3>
                <p className="mt-30">
                  Webnotics Pvt. LTD. are a strong and experienced competitor in
                  the field of Web & Mobile Apps development. We work as a team
                  with our valuable clients. This allows us to identify customer
                  needs and offer the best solution. A team of diverse
                  specialists works on each project along with dedicated project
                  manager.
                </p>
                <div className="widget-container mt-30">
                  <div className="text-editor">
                    <b>OUR CORE VALUES</b> An approach that is professional,
                    flawless execution, and a wealth of experience
                  </div>
                </div>
                <div className="widget-container mt-30">
                  <div className="text-editor">
                    <b>OUR MISSION</b> Our custom-made solutions will help you
                    reach your target audience and increase your internet
                    visibility
                  </div>
                </div>
                <div className="widget-container mt-30">
                  <div className="text-editor">
                    <b>OUR VISION</b> Digital communications must undergo a
                    radical transformation in the future
                  </div>
                </div>

                <Link to="/about-us">
                  <Button
                    variant="contained"
                    className="btn-theme-colored mt-30"
                  >
                    <span>know more</span>
                  </Button>
                </Link>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
