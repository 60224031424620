import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import webdevelopment from "../../../Assets/img/webdevelopment.png";
import "./index.scss";

export default function WebDTopSection() {
  return (
    <>
      <section className="web_design">
        <div className="background-overlay"></div>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={6}>
            <div className="wma_img">
              <img src={webdevelopment} alt="Web Design" />
            </div>
          </Grid>

          <Grid xs={12} sm={6}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h2 className="title ">
                  <span className="title-part1 ">Web Development with us!</span>
                </h2>
                <p className="mt-30">
                  Web Development Company in Germany and our developers, they
                  are focused on coding the most innovative ideas to produce
                  pixel perfect code that is error free and SEO friendly. Many
                  sites have been coded by our software developers. PHP, HTML,
                  DHTML and XML are just some of the languages that our
                  developers are familiar with. for infrastructure, banking,
                  education, e-learning and animation are just some of the areas
                  we've worked with.
                </p>
                <p>
                  There's nothing we can't handle, whether it's web development
                  in Germany and the United States, ecommerce web design or any
                  other online/application development assignment. Webnotics
                  creates responsive websites that are easy to navigate and look
                  great on all devices and platforms.
                </p>
                <a
                  href="https://join.skype.com/invite/Ybh1FyV4SeQb"
                  target="_blank"
                  rel="noreferrer"
                  className="c_link"
                >
                  <Button variant="contained" className="btn-theme-colored">
                    <span>get in touch</span>
                  </Button>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
