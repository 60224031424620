import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import chart from "../../../Assets/img/Chart.png";
import MobileChat from "../../../Assets/img/MobileChat.png";
import code from "../../../Assets/img/code.png";
import seo from "../../../Assets/img/Seo.png";
import message from "../../../Assets/img/message.png";
import webdesign from "../../../Assets/img/WebDesign.png";

import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

export default function WdTiles() {
  return (
    <>
      <div className="wdTiles">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <h2 className="subtitle">Web Design Services</h2>
            <h3 className="title">You have reasons to work with us!</h3>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={12}>
            <Box
              className="wdTiles_sec"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                m: 1,
              }}
            >
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={chart} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">Conversion Optimised</Link>
                      </h4>
                      <div className="tile-details">
                        To help you achieve your company's marketing goals, we
                        combine jaw-dropping, one-of-a-kind bespoke website
                        design with a focus on conversion optimization. To
                        handcraft the “Best from the Rest” design components, we
                        closely adhere to the instructions. We also provide
                        creative web design services with the highest precision.
                      </div>

                      <Link to="/portfolio" className="read-more">
                        <ArrowRightAltIcon /> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={MobileChat} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">Mobile Responsive</Link>
                      </h4>
                      <div className="tile-details">
                        People are increasingly shifting away from large
                        computer screens and toward small-screen gadgets. Users
                        may effectively visit your site from mobile devices,
                        iPhones, and tablets if you have a responsive website
                        design. As mobile-friendly websites are becoming more
                        popular, search engines are taking notice.
                      </div>

                      <Link to="/portfolio" className="read-more">
                        <ArrowRightAltIcon /> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={code} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">Backend Managed</Link>
                      </h4>
                      <div className="tile-details">
                        We try to build a simple backend and allow the
                        administrator to conduct operations with ease. The
                        backend should allow for simple integration and a
                        mechanism for better overall control. Our team
                        specializes in working with ready-made solutions or
                        creating bespoke code to help systems communicate with
                        one another.
                      </div>

                      <Link to="/portfolio" className="read-more">
                        <ArrowRightAltIcon /> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={seo} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">SEO Optimised</Link>
                      </h4>
                      <div className="tile-details">
                        SEO stands for "Search Engine Optimization," and it
                        refers to techniques that are implemented in line with
                        Google's algorithm to improve website performance and
                        yield a reasonable return on investment. When websites
                        are correctly optimized, the relevant traffic begins to
                        arrive, which converts into leads. So SEO is a strategy
                        for increasing a website's search engine ranking.
                      </div>

                      <Link to="/portfolio" className="read-more">
                        <ArrowRightAltIcon /> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={message} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">Free Support</Link>
                      </h4>
                      <div className="tile-details">
                        We are so confident in our constructed solutions that we
                        typically provide “Free Lifetime Support.” We are
                        constantly prepared with rolled-up sleeves to provide
                        our clients with more trustworthy services. If an issue
                        develops, you should not be concerned since we are
                        available to assist you 24 hours a day, seven days a
                        week. We can guarantee that there will be no additional
                        charges for retaining our services.
                      </div>

                      <Link to="/portfolio" className="read-more">
                        <ArrowRightAltIcon /> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={webdesign} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">Creative Web Designs</Link>
                      </h4>
                      <div className="tile-details">
                        Are you looking for a website that’s one-of-a-kind,
                        responsive, and visually appealing? If yes, then you
                        have discovered the right spot for your needs! We offer
                        cutting-edge websites that are wonderful and amazing. We
                        are here to deliver the greatest user experience
                        possible, which results in significantly greater
                        conversion rates and audience engagement.
                      </div>

                      <Link to="/portfolio" className="read-more">
                        <ArrowRightAltIcon /> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
