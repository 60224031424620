import React from "react";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ScrollToTop from "../Components/ScrollToTop";
import WebAppDesign from "../Components/WebAppDesign";
import { Helmet } from "react-helmet";

export default function WebAppDesignPage() {
  return (
    <>
      <Helmet>
        <title>
          Web App Designing in USA & Germany | Web Applications Design Services
        </title>
        <meta
          name="description"
          content="web app desig company in the USA and Germany, delivering innovative, user-friendly, and responsive designs to elevate your digital presence."
        />
      </Helmet>
      <BreadcrumbMain title="Web App Design">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Web App Design
          </Typography>
        </>
      </BreadcrumbMain>
      <WebAppDesign />
      <ScrollToTop />
    </>
  );
}
