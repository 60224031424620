import Grid from "@mui/material/Grid";
import project_discuss from "../../../Assets/img/project_discuss.webp";
import "./index.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { PopupButton } from "react-calendly";

export default function ProjectDiscuss() {
  return (
    <>
      <section className="ProjectDiscuss">
        <Grid
          container
          spacing={0}
          className="box-container"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid xs={12} sm={6}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h2 className="subtitle ">Project Discuss</h2>
                <h3 className="title ">
                  <span className="title-part1 ">
                    Get The Project Discuss Now!
                  </span>
                </h3>
                <p className="mt-30">
                  Just let us know if you have any questions regarding our
                  services.We can tell you all about our techniques and other
                  specifics.
                </p>
                <ul className="tm-sc-list mt-30">
                  <li>
                    <CheckCircleIcon />
                    <span>
                      We can help you design & develop complete experiences
                    </span>
                  </li>
                  <li>
                    <CheckCircleIcon />
                    <span>
                      This is all you’ll need to traverse the road to success
                    </span>
                  </li>
                  <li>
                    <CheckCircleIcon />
                    <span>
                      The affirmative action solutions for all your business
                      needs
                    </span>
                  </li>
                </ul>
                
                <PopupButton
                  url="https://calendly.com/webnotics/30min"
                  rootElement={document.getElementById("root")}
                  className="btn-theme-colored"
                  text={<span>Contact us</span>}
                />
              </div>
            </div>
          </Grid>
          <Grid xs={12} sm={6}>
            <div className="wma_img">
              <img src={project_discuss} alt="Project Discuss" />
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
