import * as React from "react";
import LogoCarousel from "../HomeComp/LogoCarousel";
import Stats from "../AboutUs/Stats";
import Testimonials from "../HomeComp/Testimonials";
import ChatCta from "../ChatCta";
import Methodology from "../WebDesign/Methodology";
import SapSection from "./SapSection";
import SapTiles from "./SapTiles";

export default function SapSupportAndServices() {
  return (
    <div className="WebDesign">
      <SapSection />
      <SapTiles />
      <Stats />
      <Testimonials />
      <Methodology />
      <ChatCta />
      <LogoCarousel />
    </div>
  );
}
