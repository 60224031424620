import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import career from "../../../Assets/img/career.png";

export default function TopSectionCareer() {
  return (
    <>
      <section className="career">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <h2 className="title">Nice to Meet You</h2>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          className="box-container m-b-100 text-center"
        >
          <Grid xs={12}>
            <p className="top_para">
              There are many IT companies, but none quite like Webnotics Pvt. LTD. We’ve
              been around for over 10 years but you wouldn’t guess it from the
              energy in the place. Along the way, we have picked up plenty of
              awards.
            </p>
            <img src={career} alt="career" className="w-100 mw-100" />
          </Grid>
        </Grid>
      </section>
    </>
  );
}
