import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./index.scss";
import { Grid } from "@mui/material";

export const BreadcrumbMain = (props) => {
  return (
    <>
      <Stack className="mui_breadcrumb">
        <div className="background-overlay"></div>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12}>
            <h1 className="title">{props.title}</h1>
            <Breadcrumbs
              separator={<ArrowForwardIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {props.children}
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
