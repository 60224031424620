import React from "react";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ScrollToTop from "../Components/ScrollToTop";
import WebAppDevelopment from "../Components/WebAppDevelopment";
import { Helmet } from "react-helmet";

export default function WebAppDevelopmentPage() {
  return (
    <>
      <Helmet>
        <title>
          Web App Development Services in Germany | Web Applications Development
          in USA
        </title>
        <meta
          name="description"
          content="Expert web app development services offering custom solutions, responsive designs, and seamless user experiences. Transform your ideas into powerful web applications today!"
        />
      </Helmet>
      <BreadcrumbMain title="Web App Development">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Web App Development
          </Typography>
        </>
      </BreadcrumbMain>
      <WebAppDevelopment />
      <ScrollToTop />
    </>
  );
}
