import React from "react";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ScrollToTop from "../Components/ScrollToTop";
import AndroidAppDevelopment from "../Components/AndroidAppDevelopment";
import { Helmet } from "react-helmet";

export default function AndroidAppDevelopmentPage() {
  return (
    <>
      <Helmet>
        <title>Android App Development Company in Germany, USA</title>
        <meta
          name="description"
          content="Android App development company Looking for best Android apps development services for creating an Android app project? Get Free Quote."
        />
      </Helmet>
      <BreadcrumbMain title="Android App Development">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Android App Development
          </Typography>
        </>
      </BreadcrumbMain>
      <AndroidAppDevelopment />
      <ScrollToTop />
    </>
  );
}
