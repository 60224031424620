import ScrollToTop from "../Components/ScrollToTop";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import Career from "../Components/Career";

export default function CareerPage() {
  return (
    <>
      <BreadcrumbMain title="Careers">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Careers
          </Typography>
        </>
      </BreadcrumbMain>
      <Career />
      <ScrollToTop />
    </>
  );
}
