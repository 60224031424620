import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import android_app from "../../../Assets/img/android_app.png";
import "./index.scss";

export default function AndroidAppDevelopmentTopSection() {
  return (
    <>
      <section className="web_design">
        <div className="background-overlay"></div>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={6}>
            <div className="wma_img">
              <img src={android_app} alt="Android App Development" />
            </div>
          </Grid>

          <Grid xs={12} sm={6}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h2 className="title">
                  <span className="title-part1">
                    Customized Android Apps for your brand.
                  </span>
                </h2>
                <p className="mt-30">
                  Android App Development - For more than 10 years, we have been
                  developing Android apps for the Google Play Store. To help IT
                  entrepreneurs with top tech stacks, we are developing an
                  android app that has a proven track record of performance.
                </p>
                <p>
                  This company is one of the best in India for Android app
                  development. This includes apps used in a variety of
                  industries, including business, technology and healthcare as
                  well as media and entertainment to mention a few. We can help
                  you create Android apps that are independent, client-server,
                  web service-driven, or database-driven.
                </p>
                <p>
                  Webnotics Android application designers are well-versed in
                  building Javascript-based applications for Android devices
                  that run locally. In addition, our Android app developers have
                  experience in cross-platform development, including the
                  migration of current mobile applications or Java-based apps to
                  the Android platform.
                </p>

                <a
                  href="https://join.skype.com/invite/Ybh1FyV4SeQb"
                  target="_blank"
                  rel="noreferrer"
                  className="c_link"
                >
                  <Button variant="contained" className="btn-theme-colored">
                    <span>get in touch</span>
                  </Button>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
