import BlogList from "../Components/BlogList/BlogList";
import ScrollToTop from "../Components/ScrollToTop";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import "./Blogs.scss";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import axios from "axios";

const Blog = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchdata, setfetchdata] = useState([]);
  const handlePageChange = (event, page) => {
    event.preventDefault();
    setCurrentPage(page);
  };

  useEffect(()=>{
    const fetchblog = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/blogs`);
        //console.log("get all blogs--->", response);
        setfetchdata(response.data);
        
      } catch (error) {
        //console.log("get all blogs error-->", error);
      }
    };
    fetchblog();
  }, []);

  //console.log("fetchdata bbbbbb--->", fetchdata);
  
  const totalPageCount = (Math.ceil(fetchdata.length / 9)+1);
  const isLastPage = currentPage === totalPageCount;
  //console.log("total page count-->",totalPageCount)
  return (
    <>
      <BreadcrumbMain title="Our Blogs">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Blog
          </Typography>
        </>
      </BreadcrumbMain>
      <div className="blogs">
        <BlogList page={currentPage} data={data} />
        <Stack spacing={2} className="custom_paginate">
        <Pagination
            count={totalPageCount-1}
            page={currentPage}
            onChange={handlePageChange}
            disabled={isLastPage}
          />
        </Stack>
      </div>

      <ScrollToTop />
    </>
  );
};

export default Blog;
