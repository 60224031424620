import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import about from "../../../Assets/img/about.png";
import "./index.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import parse from 'html-react-parser';

export default function AboutWebnotics() {
  const [fetchdata, setfetchdata] = useState([]);
useEffect(()=>{
  const fetchservices = async()=>{
    await axios.get(`${process.env.REACT_APP_API_URL}/about`)
    .then(response=>{
      //console.log("get about--->", response)
      setfetchdata(response.data);
    })
    .catch(error=>{
      //console.log("get all about error-->", error)
    })
  }
  fetchservices();
}, []);
const ab = `<div>${fetchdata.description}</div>`
  return (
    <>
      <section className="AboutWebnotics">
        <div className="background-overlay"></div>
        <Grid
          container
          spacing={0}
          className="box-container"
          justifyContent="center"
          alignItems="center"
        >
          <Grid xs={12} sm={6}>
            <div className="wma_img">
              <img src={about} alt="about us" />
            </div>
          </Grid>

          <Grid xs={12} sm={6}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h2 className="subtitle ">{fetchdata.title}</h2>
                <h3 className="title ">
                  <span className="title-part1 ">
                    {fetchdata.sub_title}
                  </span>
                </h3>
                <p className="mt-30">
                 {parse(ab)}
                </p>

                <Link to="/our-capabilities">
                  <Button variant="contained" className="btn-theme-colored">
                    <span>View all service</span>
                  </Button>
                </Link>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
