import Grid from "@mui/material/Grid";
import "./index.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
//import bg23 from "../../../Assets/img/bg23.jpg";
import { Link } from "react-router-dom";
//import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";

export default function FullRangeServices() {
  const [fetchdata, setfetchdata] = useState([]);
useEffect(()=>{
  const fetchservices = async()=>{
    await axios.get(`${process.env.REACT_APP_API_URL}/services`)
    .then(response=>{
      ////console.log("get all services--->", response)
      setfetchdata(response.data);
    })
    .catch(error=>{
      //console.log("get all services error-->", error)
    })
  }
  fetchservices();
}, []);
  return (
    <section className="fulle_range_service">
      <Grid container spacing={2} className="box-container">
        <Grid xs={12} sm={6} md={3}>
          <div className="title-wrapper">
            <h2 className="subtitle ">We Offer</h2>
            <h3 className="title ">
              <span className="title-part1 ">
                We Provide Full Range Services
              </span>
            </h3>
          </div>
        </Grid>
        {fetchdata.map((el) => (
        <Grid xs={12} sm={6} md={3} key={el.id}>
          <div className="service-item-current">
            <div className="thumb">
              <img src={`https://node.builtdemo.info/${el.image_path}`} className="img-fullwidth" alt="img" />
            </div>
            <div className="content">
              <div className="service-icon icon">
                <div className="icon">
                  <CheckCircleIcon />
                </div>
              </div>
              <h4 className="title">
                <Link to={el.link}>{el.title}</Link>
              </h4>
            </div>
          </div>
        </Grid>
        ))}
        {/*<Grid xs={12} sm={6} md={3}>
          <div className="service-item-current">
            <div className="thumb">
              <img src={bg23} className="img-fullwidth" alt="img" />
            </div>
            <div className="content">
              <div className="service-icon icon">
                <div className="icon">
                  <CheckCircleIcon />
                </div>
              </div>
              <h4 className="title">
                <Link to="/">Web Development</Link>
              </h4>
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <div className="service-item-current">
            <div className="thumb">
              <img src={bg23} className="img-fullwidth" alt="img" />
            </div>
            <div className="content">
              <div className="service-icon icon">
                <div className="icon">
                  <CheckCircleIcon />
                </div>
              </div>
              <h4 className="title">
                <Link to="/">Web Application</Link>
              </h4>
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <div className="service-item-current">
            <div className="thumb">
              <img src={bg23} className="img-fullwidth" alt="img" />
            </div>
            <div className="content">
              <div className="service-icon icon">
                <div className="icon">
                  <CheckCircleIcon />
                </div>
              </div>
              <h4 className="title">
                <Link to="/">UI UX Designing</Link>
              </h4>
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <div className="service-item-current">
            <div className="thumb">
              <img src={bg23} className="img-fullwidth" alt="img" />
            </div>
            <div className="content">
              <div className="service-icon icon">
                <div className="icon">
                  <CheckCircleIcon />
                </div>
              </div>
              <h4 className="title">
                <Link to="/">CMS Design & Development</Link>
              </h4>
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <div className="range-box">
            <div className="range-box-wrapper">
              <div className="range-text">
                <div className="content">
                  <p>Get Solutions for Web Design</p>
                </div>
                <h4 className="range-box-title">
                  Trust the experts for all your web design &amp; development
                  needs{" "}
                </h4>
                <Link to="/contact-us">
                  <Button variant="contained" className="btn-theme-colored">
                    <span>Find your solution</span>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Grid>*/}
      </Grid>
    </section>
  );
}
