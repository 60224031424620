import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./footer.scss";
//import logo from "../../Assets/img/logo.svg";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useEffect, useState } from "react";
import axios from "axios";

export default function Footer() {
  const [fetchdata, setFetchdata] = useState("");
  const today = new Date();
  const year = today.getFullYear();
  useEffect(() => {
    const footerData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/website-setting`)
        .then((response) => {
          ////console.log("top footer data-->", response)
          setFetchdata(response.data);
        })
        .catch((error) => {
          //console.log(error)
        });
    };
    footerData();
  }, []);
  return (
    <Box className="footer">
      <Grid container spacing={0}>
        <Grid xs={12} md={3}>
          {/*<img src={logo} alt="logo"/>*/}
          <img
            src={`https://node.builtdemo.info/${fetchdata.logo_path}`}
            alt="logo"
          />
          <div>
            <p className="text">
              Driven by passion & ideas and fueled by expertise and great
              know-how, we are providing 360 degree of creative services to
              serve our clients with a hassle free experience.
            </p>
            <div className="social_icon">
              <a
                href="https://www.facebook.com/webnoticsofficial"
                aria-label="Facebook Link"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://twitter.com/Webnoticsltd"
                aria-label="Twitter Link"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/webnoticsofficial"
                aria-label="Linkedin Link"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon />
              </a>
            </div>
          </div>
        </Grid>
        <Grid xs={12} md={9}>
          <Grid container className="items">
            <Grid xs={12} md={3}>
              <h3>Company</h3>
              <ul>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/why-choose-us">Why Choose us</Link>
                </li>
                <li>
                  <Link to="/careers">Career</Link>
                </li>
                <li>
                  <a
                    href="https://webnotics.solutions/sitemap.xml"
                    aria-label="Sitemap Link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sitemap
                  </a>
                </li>
                <li>
                  <Link to="/terms-conditions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/refund-cancellation-policy">
                    Refund & Cancellation Policy
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid xs={12} md={5}>
              <h3>Capabilities</h3>
              <ul>
                <li>
                  <Link to="/website-design-and-developement">
                    Website Design & Development
                  </Link>
                </li>
                <li>
                  <Link to="/web-design"> Web Design</Link>
                </li>
                <li>
                  <Link to="/cms-design-developement">
                    CMS Design & Development
                  </Link>
                </li>
                <li>
                  <Link to="/web-development">Web Development</Link>
                </li>
                <li>
                  <Link to="/web-application-design-developement">
                    Web Application Design & Development
                  </Link>
                </li>
                <li>
                  <Link to="/web-app-design"> Web App Design</Link>
                </li>
                <li>
                  <Link to="/web-app-development">Web App Development</Link>
                </li>
              </ul>
            </Grid>
            <Grid xs={12} md={4}>
              <h3>Contact Info</h3>
              <div className="contact_text">
                {/* <p>{fetchdata.website_address}</p> */}

                <p className="c_list">
                  <LocationOnIcon /> <span>{fetchdata.website_address}</span>
                </p>
                <p className="c_list">
                  <LocationOnIcon />{" "}
                  <span>
                    Lackerbauerstraße
                    <br />
                    House no.- 3<br />
                    Munich, BY 81241
                    <br />
                    Germany
                  </span>
                </p>
                <p className="c_list">
                  <MailOutlineIcon />{" "}
                  <a href={"mailto:" + fetchdata.website_email}>
                    {fetchdata.website_email}
                  </a>
                </p>
                <p className="c_list">
                  <SmartphoneIcon />{" "}
                  <a href={"tel:" + fetchdata.website_phone}>
                    +{fetchdata.website_phone}
                  </a>
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0} className="bottom_footer">
        <Grid xs={12}>
          <p>
            Copyright © {year} <Link to="/">Webnotics Solutions Pvt. LTD.</Link>
          </p>
        </Grid>
      </Grid>
    </Box>
  );
}
