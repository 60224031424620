import Grid from "@mui/material/Grid";
import "./index.scss";
import HailIcon from "@mui/icons-material/Hail";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TouchAppIcon from "@mui/icons-material/TouchApp";

export default function HomeHelp() {
  return (
    <>
      <section className="home_help">
        <Grid container spacing={0} className="box-container">
          <Grid xs={12}>
            <div className="wma_content">
              <div className="title-wrapper text-center">
                <h2 className="subtitle ">We are here to help you!!</h2>
                <h3 className="title ">
                  <span className="title-part1 ">Let's get head-to-head</span>
                </h3>
              </div>
            </div>
          </Grid>
          <Grid xs={12} sm={4}>
            <div className="help-current-item">
              <div className="inner-box">
                <div className="icon-box">
                  <div className="icon">
                    <SupportAgentIcon />
                  </div>
                </div>
                <h4 className="help-title">Email Support</h4>
                <div className="help-details">
                  <p>
                    Let's get head-to-headWe leave no stone unturned in our
                    efforts to provide you with the finest possible service.
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={12} sm={4}>
            <div className="help-current-item">
              <div className="inner-box">
                <div className="icon-box">
                  <div className="icon">
                    <TouchAppIcon />
                  </div>
                </div>
                <h4 className="help-title">
                  Get in touch on your preferred IM.
                </h4>
                <div className="help-details">
                  <p>
                    Get connected with professionals at Webnotics Solutions on
                    Skype, Whatsapp, Zoom, Google Meet, or on your preferred IM.
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={12} sm={4}>
            <div className="help-current-item">
              <div className="inner-box">
                <div className="icon-box">
                  <div className="icon">
                    <HailIcon />
                  </div>
                </div>
                <h4 className="help-title">Need To Meet In person ?</h4>
                <div className="help-details">
                  <p>
                    For clients in the United States, Europe, and Australia, we
                    are available in person.
                  </p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
