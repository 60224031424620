import * as React from "react";
import "./index.scss";
import LogoCarousel from "../HomeComp/LogoCarousel";
import Testimonials from "../HomeComp/Testimonials";
import ChatCta from "../ChatCta";
import Methodology from "../WebDesign/Methodology";
import WdadCapabilityTiles from "./WdadCapabilityTiles";
import WDDWdTiles from "./WdTiles";

export default function WebsiteDesignDevelopment() {
  return (
    <div className="WebDesign">
      <WdadCapabilityTiles />
      <WDDWdTiles />
      <Testimonials />
      <Methodology />
      <ChatCta />
      <LogoCarousel />
    </div>
  );
}
