import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Button } from "@mui/material";

export default function ImpactIndustries() {
  const TilesData = [
    {
      name: "Retail",
    },
    {
      name: "Logistics",
    },
    {
      name: "Fitness & Healthcare",
    },
    {
      name: "Sports",
    },
    {
      name: "Healthcare",
    },
    {
      name: "Law",
    },
    {
      name: "Finance",
    },
    {
      name: "Tour & Travel",
    },
    {
      name: "Real Estate",
    },
    {
      name: "Utilities",
    },
    {
      name: "Digital & Creative",
    },
    {
      name: "Manufacturing",
    },
    {
      name: "Media",
    },
    {
      name: "Non Profits",
    },
    {
      name: "Non Profits",
    },
    {
      name: "Fashion",
    },
  ];
  return (
    <>
      <div className="ImpactIndustries">
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} className="text-center">
            <h2 className="subtitle">
              Strengthen your customer relationships with engaging brand
              experiences powered by latest technologies.
            </h2>
            <h3 className="title">Industries We Impact</h3>
          </Grid>
          {TilesData.map((item, index) => (
            <Grid xs={12} sm={3} key={index}>
              <div className="tiles">
                <span>{item.name}</span>
                <KeyboardDoubleArrowRightIcon />
              </div>
            </Grid>
          ))}
          <Grid xs={12} className="text-center">
            <a href="https://calendly.com/webnotics/30min" className="c_link">
              <Button variant="contained" className="m-t-30 btn-theme-colored">
                <span>Book A Call Now</span>
              </Button>
            </a>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
