import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import cms from "../../../Assets/img/cms.png";
import "./index.scss";

export default function CmsTopSection() {
  return (
    <>
      <section className="CmsTopSection">
        <div className="background-overlay"></div>
        <Grid
          container
          spacing={0}
          className="box-container"
         
        >
          <Grid xs={12} sm={6}>
            <div className="wma_img">
              <img src={cms} alt="Cms Design and Development" />
            </div>
          </Grid>

          <Grid xs={12} sm={6}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h2 className="title ">
                  <span className="title-part1 ">
                    Why should you get the custom CMS website development
                    services for your business?
                  </span>
                </h2>
                <p className="mt-30">
                  CMS Design & Development company based in Germany and USA -
                  Website content management systems have come a long way since
                  the 1990s when site owners began using languages such as PHP
                  to manage and modify their material. As a bespoke CMS and
                  website building company, Webnotics Solutions has been in
                  business since 2008, when the open-source solutions we use
                  today were released.
                </p>
                <p>
                  Whatever CMS and web development services you need, we're
                  certain that we can optimize your system for the greatest
                  performance possible. There are several advantages to using a
                  Material Management System (CMS), including the fact that you
                  are in charge of your content. It is important to us that you
                  fully grasp your organization's and your users' needs so that
                  we can address them in an agile manner.
                </p>
                <p>
                  Known as the Best CMS Development Agency, Webnotics Solutions
                  offers a CMS website design with a wholly changed, entirely
                  marked design that varies according to screen size.
                  Cost-effective. Greater efficiency. Uncomplicated updating of
                  cutting-edge technologies Additional modules can be added as
                  needed. Sites that may be simply managed and updated on your
                  own after arrangement are what we specialize in as a leading
                  CMS Web Design Company.
                </p>

                <a
                  href="https://join.skype.com/invite/Ybh1FyV4SeQb"
                  target="_blank"
                  rel="noreferrer"
                  className="c_link"
                >
                  <Button variant="contained" className="btn-theme-colored">
                    <span>get in touch</span>
                  </Button>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
