import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import ScrollToTop from "../Components/ScrollToTop";
import "./Blogs.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

const BlogPost = () => {
  const { id } = useParams();
  //console.log("iiii-->", id)
  const [fetchdata, setfetchdata] = useState([]);
  useEffect(() => {
    const fetchblog = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/blog/${id}`)
        .then((response) => {
          //console.log("get all blos blogpost 11111 --->", response)
          setfetchdata(response.data);
        })
        .catch((error) => {
          //console.log("get all blogs error-->", error)
        });
    };
    fetchblog();
  }, [id]);
  //const target = fetchdata.filter((el) => el.id === id)[0];
  ////console.log("trget-->",target)
  const a = `<div>${fetchdata.blog_description}</div>`;
  const options = { year: "numeric", month: "long", day: "numeric" };
  return (
    <>
      <Helmet>
        <title>{fetchdata.blog_meta_title}</title>
        <meta name="description" content={fetchdata.blog_meta_description} />
      </Helmet>
      <div className="posts">
        <img
          src={`https://node.builtdemo.info/public/${fetchdata.image_path}`}
          className="img"
          alt="img"
        />
        <p className="post__date">
          {new Date(fetchdata.publish_date).toLocaleDateString(
            "en-US",
            options
          )}
        </p>
        {/*<h1>{fetchdata.blog_title}</h1>*/}

        <p className="post__content">{parse(a)}</p>
      </div>
      <ScrollToTop />
    </>
  );
};

export default BlogPost;
