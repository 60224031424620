import * as React from "react";
import "./index.scss";
import LogoCarousel from "../HomeComp/LogoCarousel";
import Stats from "../AboutUs/Stats";
import Testimonials from "../HomeComp/Testimonials";
import ChatCta from "../ChatCta";
import AndroidAppDevelopmentTopSection from "./AndroidAppDevelopmentTopSection";
import AndroidAppDevelopmentTopSectionTiles from "./AndroidAppDevelopmentTopSectionTiles";
import Methodology from "../WebDesign/Methodology";

export default function AndroidAppDevelopment() {
  return (
    <div className="WebDesign">
      <AndroidAppDevelopmentTopSection />
      <AndroidAppDevelopmentTopSectionTiles />
      <Stats />
      <Testimonials />
      <Methodology />
      <ChatCta />
      <LogoCarousel />
    </div>
  );
}
