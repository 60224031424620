import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import wma from "../../../Assets/img/wma.jpg";
import "./index.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function WMAService() {
  return (
    <>
      <section className="wma_service">
        <div className="background-overlay"></div>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={6}>
            <div className="wma_img">
              <img src={wma} alt="web and mobile services" />
            </div>
          </Grid>

          <Grid xs={12} sm={6}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h1 className="subtitle ">Get to Know </h1>
                <h2 className="title ">
                  Web & Mobile App Services in One Place
                </h2>
                <p className="mt-30">
                  When it comes to developing web-based and mobile-based
                  applications, we are formidable competition. Our valued
                  clientele and we work together as a team. In this way, we can
                  determine client demands and provide the best solution. Each
                  project is overseen by a project manager and a team of
                  professionals. Founded in 2004, Webnotics is an IT & Software
                  business that provides end-to-end solutions for websites,
                  online applications, and mobile apps.
                </p>
                <ul className="tm-sc-list mt-30">
                  <li>
                    <CheckCircleIcon />
                    <span>Creative idea</span>
                  </li>
                  <li>
                    <CheckCircleIcon />
                    <span>Online support</span>
                  </li>
                  <li>
                    <CheckCircleIcon />
                    <span>Competitive prices</span>
                  </li>
                  <li>
                    <CheckCircleIcon />
                    <span>A talented team of Experts</span>
                  </li>
                </ul>
                <Link to="/our-capabilities">
                  <Button variant="contained" className="btn-theme-colored">
                    <span>View all service</span>
                  </Button>
                </Link>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
