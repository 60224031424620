import ScrollToTop from "../Components/ScrollToTop";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import TermsAndConditions from "../Components/TermsAndConditions";

export default function TermsAndConditionsPage() {
  return (
    <>
      <BreadcrumbMain title="Terms & Conditions">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Terms & Conditions
          </Typography>
        </>
      </BreadcrumbMain>
      <TermsAndConditions />
      <ScrollToTop />
    </>
  );
}
