import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";

import "./logocarousel.scss";

import partner1 from "../../../Assets/img/partner1.png";
import partner2 from "../../../Assets/img/partner2.png";
import partner3 from "../../../Assets/img/partner3.png";
import partner4 from "../../../Assets/img/partner4.png";

export default function PartnerCarousel() {
  return (
    <>
      <div className="wma_content partner_sec">
        <div className="title-wrapper">
          <h3 className="title ">
            <span className="title-part1 ">Our Partners</span>
          </h3>
        </div>
      </div>
      <div className="PartnerCarousel">
        <Swiper
          modules={[Autoplay]}
          slidesPerView={1}
          spaceBetween={10}
          autoplay={{
            delay: 2000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          loop
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={partner1} alt="logo" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={partner2} alt="logo" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={partner3} alt="logo" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={partner4} alt="logo" />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
