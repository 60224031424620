import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import "./header.scss";
import "@szhsin/react-menu/dist/index.css";
import TopBar from "./TopBar";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../Assets/img/logo.svg";
import { $ } from "react-jquery-plugin";
import { PopupButton } from "react-calendly";

function HeaderNew() {
  const [click, setClick] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleActive = (index) => {
    setActiveIndex(index);
  };
  const handleClick = () => {
    setClick(!click);
  };
  const closeMobileMenu = () => {
    setClick(false);
  };
  useEffect(() => {
    $(".Btm_Header .nav-links li a").hover(function () {
      $(".nav-links li .drop-menu").addClass("hide");
      $(".nav-links li .mega-box").addClass("hide");
    });
    $(".Btm_Header .nav-links li a").on("click", function () {
      $(".nav-links li .drop-menu").removeClass("hide");
      $(".nav-links li .mega-box").removeClass("hide");
    });
  }, []);

  return (
    <>
      <div className="main_header Btm_Header">
        <TopBar />
        <nav>
          <div className="wrapper">
            <div className="logo">
              <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <ul className={click ? " nav-links active" : "nav-links"}>
              <li
                className={activeIndex === 0 ? "lists active" : "lists"}
                onClick={() => handleActive(0)}
              >
                <Link to="/" onClick={closeMobileMenu}>
                  Home
                </Link>
              </li>

              <li
                className={activeIndex === 1 ? "lists active" : "lists"}
                onClick={() => handleActive(1)}
              >
                <Link to="#" className="desktop-item">
                  Company
                </Link>
                <input type="checkbox" id="showDrop" />
                <label htmlFor="showDrop" className="mobile-item">
                  Company
                </label>
                <ul className="drop-menu">
                  <li>
                    <Link to="/about-us" onClick={closeMobileMenu}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/why-choose-us" onClick={closeMobileMenu}>
                      Why Choose Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/locations" onClick={closeMobileMenu}>
                      Locations
                    </Link>
                  </li>
                  <li>
                    <Link to="/careers" onClick={closeMobileMenu}>
                      Careers
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={activeIndex === 2 ? "lists active" : "lists"}
                onClick={() => handleActive(2)}
              >
                <Link to="#" className="desktop-item">
                  Capabilities
                </Link>
                <input type="checkbox" id="showMega" />
                <label htmlFor="showMega" className="mobile-item">
                  Capabilities
                </label>

                <div className="mega-box">
                  <div className="content">
                    {/* <div className="row">
                      <header>
                        <Link
                          to="/full-stack-development"
                          onClick={closeMobileMenu}
                        >
                          Full Stack Development
                        </Link>
                      </header>
                    </div> */}
                    <div className="row no-brdr">
                      <header>Web Design & Development</header>
                      <ul className="mega-links">
                        <li>
                          <Link to="/web-design" onClick={closeMobileMenu}>
                            Web Design
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/cms-design-developement"
                            onClick={closeMobileMenu}
                          >
                            CMS Design & Development
                          </Link>
                        </li>
                        <li>
                          <Link to="/web-development" onClick={closeMobileMenu}>
                            Web Development
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <header>Web Application Design & Development</header>
                      <ul className="mega-links" onClick={closeMobileMenu}>
                        <li>
                          <Link to="/web-app-design">Web App Design</Link>
                        </li>
                        <li>
                          <Link
                            to="/web-app-development"
                            onClick={closeMobileMenu}
                          >
                            Web App Development
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <header>Mobile App Services</header>
                      <ul className="mega-links">
                        <li>
                          <Link
                            to="/android-app-development"
                            onClick={closeMobileMenu}
                          >
                            Android App Development
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/ios-app-development"
                            onClick={closeMobileMenu}
                          >
                            iOS App Development
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <header>Other Services</header>
                      <ul className="mega-links">
                        <li>
                          <Link to="/Industrial-iiot" onClick={closeMobileMenu}>
                            Industrial IIOT
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/sap-support-services"
                            onClick={closeMobileMenu}
                          >
                            SAP Support & Services
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/seo-optimization"
                            onClick={closeMobileMenu}
                          >
                            SEO Optimization
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li
                className={activeIndex === 3 ? "lists active" : "lists"}
                onClick={() => handleActive(3)}
              >
                <Link to="/portfolio" onClick={closeMobileMenu}>
                  Portfolio
                </Link>
              </li>
              <li
                className={activeIndex === 4 ? "lists active" : "lists"}
                onClick={() => handleActive(4)}
              >
                <Link to="/contact-us" onClick={closeMobileMenu}>
                  Contact Us
                </Link>
              </li>
              <li
                className={activeIndex === 5 ? "lists active" : "lists"}
                onClick={() => handleActive(5)}
              >
                <Link to="/blog" onClick={closeMobileMenu}>
                  Blog
                </Link>
              </li>
            </ul>
            <div className="call_action">
              <PopupButton
                url="https://calendly.com/webnotics/30min"
                rootElement={document.getElementById("root")}
                className="btn-theme-colored"
                text={<span>Get Quote</span>}
              />
            </div>
            <label className="btn menu-btn" htmlFor="menu-btn">
              <div className="menu-icon" onClick={handleClick}>
                <>
                  {click ? (
                    <div className="close_icon">
                      <CloseIcon />
                    </div>
                  ) : (
                    <MenuIcon />
                  )}
                </>
              </div>
            </label>
          </div>
        </nav>
      </div>
      <Outlet />
    </>
  );
}

export default HeaderNew;
