import * as React from "react";
import "./index.scss";
import LogoCarousel from "../HomeComp/LogoCarousel";
import Stats from "../AboutUs/Stats";
import Testimonials from "../HomeComp/Testimonials";
import ChatCta from "../ChatCta";
import Methodology from "../WebDesign/Methodology";
import IosAppDevelopmentTopSection from "./IosAppDevelopmentTopSection";
import IosAppDevelopmentTiles from "./IosAppDevelopmentTiles";

export default function IosAppDevelopment() {
  return (
    <div className="WebDesign">
      <IosAppDevelopmentTopSection />
      <IosAppDevelopmentTiles />
      <Stats />
      <Testimonials />
      <Methodology />
      <ChatCta />
      <LogoCarousel />
    </div>
  );
}
