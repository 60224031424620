import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import android_app from "../../../Assets/img/android_app.png";
import "./index.scss";

export default function IosAppDevelopmentTopSection() {
  return (
    <>
      <section className="web_design">
        <div className="background-overlay"></div>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={6}>
            <div className="wma_img">
              <img src={android_app} alt="Android App Development" />
            </div>
          </Grid>

          <Grid xs={12} sm={6}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h2 className="title ">
                  <span className="title-part1">
                    Bespoke iOS App Development for your brand.
                  </span>
                </h2>
                <p className="mt-30">
                  There is an increasing interest in iPhone devices on a global
                  basis, as they are seen as the gadget for the elite.
                  Businesses try to take advantage of this by reaching out to
                  this user group. So it's no wonder that the demand for iPhone
                  app development is rising in the United States and Germany. To
                  make a lasting impact on buyers, these applications must be
                  enhanced with the right features and technology. An iOS App
                  Development Company in Germany & USA is typically hired to
                  help firms establish their presence in the App Store.
                </p>
                <p>
                  The correct pick is Webnotics Solution if you're looking for a
                  partner that can provide reliability, competence, and
                  knowledge. Being in business for over 20 years, as well as
                  having built a wide selection of iOS apps for different
                  clients, sets us unique. There are seasoned specialists on our
                  iOS app development team that are well-versed in this
                  platform, as well as the newest mobile development.
                </p>

                <a
                  href="https://join.skype.com/invite/Ybh1FyV4SeQb"
                  target="_blank"
                  rel="noreferrer"
                  className="c_link"
                >
                  <Button variant="contained" className="btn-theme-colored">
                    <span>get in touch</span>
                  </Button>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
