import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import ProjectManagement from "../../../Assets/img/ProjectManagement.svg";
import UserNetworking from "../../../Assets/img/UserNetworking.svg";
import mbg from "../../../Assets/img/methodolocy_bg.png";
import IiotStats from "../IiotStats";

export default function IiotBottomSection() {
  return (
    <>
      <div className="IiotBottomSection">
        <Grid container spacing={0} className="box-container tiles_section">
          <Grid xs={12} sm={12}>
            <Grid container spacing={0} className="tiles_section_sec">
              <Grid xs={12} sm={12} className="mb-50">
              <IiotStats />
              </Grid>
              <Grid xs={12} sm={6}>
                <div className="choose_tile green">
                  <div className="icon">
                    {" "}
                    <img src={ProjectManagement} alt="img" />
                  </div>
                  <h2>
                    Webnotics' Proficiency in IIoT Platform Management:
                    Enhancing Uptime, Accelerating Market Entry, and Delivering
                    Exceptional Support
                  </h2>
                  <p>
                    Webnotics, with its specialized knowledge in managing
                    Industrial Internet of Things (IIoT) platforms,
                    significantly enhances platform reliability and uptime. This
                    expertise is pivotal in expediting the time-to-market for
                    products and services, especially for leaders in the
                    billion-dollar industrial AI and IoT sectors. Additionally,
                    Webnotics is renowned for its world-class customer support,
                    offering comprehensive and responsive assistance to its
                    clients, thereby ensuring smooth operations and client
                    satisfaction in the dynamic field of industrial AI and IoT.
                  </p>
                  <img src={mbg} className="bg_img" alt="img" />
                </div>
              </Grid>
              <Grid xs={12} sm={6}>
                <div className="choose_tile blue">
                  <div className="icon">
                    {" "}
                    <img src={UserNetworking} alt="img" />
                  </div>
                  <h2>
                    Webnotics' Comprehensive Solution Transforms Global
                    Automotive Manufacturing
                  </h2>
                  <p>
                    Webnotics' turnkey approach has revolutionized Assembly
                    Operations Intelligence for a leading Global Automotive
                    Manufacturer. This solution significantly enhanced Worker
                    Productivity, streamlining the manufacturing process.
                    Additionally, it accelerated Product Traceability, ensuring
                    a more efficient and transparent production line. This
                    holistic strategy exemplifies Webnotics' capability to
                    innovate and optimize in the automotive manufacturing
                    sector.
                  </p>
                  <img src={mbg} className="bg_img" alt="img" />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
