import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Components/Layout";
import HomePage from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import PortfolioPage from "./Pages/Portfolio";
import Blog from "./Pages/Blog";
import BlogPost from "./Pages/BlogPost";
import data from "./data/data";
import ContactUs from "./Pages/ContactUs";
import AboutPage from "./Pages/About";
import WhyChooseUsPage from "./Pages/WhyChooseUs";
import Locations from "./Pages/Locations";
import CareerPage from "./Pages/Career";
// import FullStackDevelopmentPage from "./Pages/FullStackDevelopment";
import WebDesignPage from "./Pages/WebDesign";
import CmsDesignandDevelopmentPage from "./Pages/CmsDesignandDevelopment";
import WebDevelopmentPage from "./Pages/WebDevelopment";
import WebAppDesignPage from "./Pages/WebAppDesign";
import WebAppDevelopmentPage from "./Pages/WebAppDevelopment";
import AndroidAppDevelopmentPage from "./Pages/AndroidAppDevelopment";
import IosAppDevelopmentPage from "./Pages/IosAppDevelopment";
import SapSupportAndServicesPage from "./Pages/SapSupportAndResources";
import SeoOtimizationPage from "./Pages/SeoOptimization";
import OurCapabilitiesPage from "./Pages/OurCapabilities";
import TermsAndConditionsPage from "./Pages/TermsConditions";
import PrivacyPolicyPage from "./Pages/PrivacyPolicy";
import RefundPolicyPage from "./Pages/RefundPolicy";
import WebsiteDesignDevelopmentPage from "./Pages/WebsiteDesignDevelopment";
import WebApplicationDesignAndDevelopementPage from "./Pages/WebApplicationDesignAndDevelopement";
import ScrollTopBtn from "./Components/ScrollTopBtn";
import WebnoticsIiotPage from "./Pages/WebnoticsIiot";
import ThankYouPage from "./Pages/ThankYou";

function App() {
  return (
    <div className="App">
      {/* <BrowserRouter basename="/webnotics"> */}
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/*" element={<NotFound />} />
            <Route index path="/" element={<HomePage />} />
            <Route path="portfolio" element={<PortfolioPage />} />
            <Route path="/about-us" element={<AboutPage />} />
            <Route path="/why-choose-us" element={<WhyChooseUsPage />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/locations" element={<Locations />} />
            <Route path="/careers" element={<CareerPage />} />
            {/* <Route
              path="/full-stack-development"
              element={<FullStackDevelopmentPage />}
            /> */}
            <Route path="/web-design" element={<WebDesignPage />} />
            <Route
              path="/cms-design-developement"
              element={<CmsDesignandDevelopmentPage />}
            />
            <Route path="/web-development" element={<WebDevelopmentPage />} />
            <Route path="/web-app-design" element={<WebAppDesignPage />} />
            <Route
              path="/web-app-development"
              element={<WebAppDevelopmentPage />}
            />
            <Route
              path="/website-design-and-developement"
              element={<WebsiteDesignDevelopmentPage />}
            />
            <Route
              path="/web-application-design-developement"
              element={<WebApplicationDesignAndDevelopementPage />}
            />
            <Route
              path="/android-app-development"
              element={<AndroidAppDevelopmentPage />}
            />
            <Route
              path="/ios-app-development"
              element={<IosAppDevelopmentPage />}
            />
            <Route
              path="/sap-support-services"
              element={<SapSupportAndServicesPage />}
            />
            <Route path="/seo-optimization" element={<SeoOtimizationPage />} />
            <Route path="/our-capabilities" element={<OurCapabilitiesPage />} />
            <Route
              path="/terms-conditions"
              element={<TermsAndConditionsPage />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/Industrial-iiot" element={<WebnoticsIiotPage />} />
            <Route
              path="/refund-cancellation-policy"
              element={<RefundPolicyPage />}
            />
            <Route path="/thank-you" element={<ThankYouPage />} />
            <Route path="/blog" element={<Blog data={data} />} />
            <Route path="/:id" element={<BlogPost data={data} />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ScrollTopBtn />
    </div>
  );
}

export default App;
