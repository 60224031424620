import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import meth1 from "../../../Assets/img/meth1.svg";
import meth2 from "../../../Assets/img/meth2.svg";
import meth3 from "../../../Assets/img/meth4.svg";
import meth4 from "../../../Assets/img/meth3.svg";
import meth5 from "../../../Assets/img/meth5.svg";
import mbg from "../../../Assets/img/methodolocy_bg.png";
import { PopupButton } from "react-calendly";

import { Box } from "@mui/material";

export default function Methodology() {
  return (
    <>
      <div className="Methodology">
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={12}>
            <Box
              className="Methodology_sec"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 0,
                m: 0,
              }}
            >
              <div className="choose_tile detail mt__15 ">
                <h3 className="title">Work Methodology in Depth</h3>

                <p>
                  When it comes to Digital Marketing, and Mobile Application
                  Development at Webnotics, we have a wealth of expertise. The
                  team is composed of UI-UX designers, mobile developers, PHP
                  developers, digital marketers, and project managers. Take a
                  deeper look!
                </p>
                <PopupButton
                  url="https://calendly.com/webnotics/30min"
                  rootElement={document.getElementById("root")}
                  className="btn-theme-colored mb-50"
                  text={<span>Book a call</span>}
                />
              </div>
              <div className="choose_tile red">
                <img src={meth1} alt="img" className="icon" />
                <h2>Analysis</h2>
                <p>
                  Our technical analysts and consultants undertake several
                  functional assessments, feasibility studies, data flow
                  structures, and strong system architecture designs to produce
                  a scalable and high-performance working prototype of the
                  project.
                </p>
                <img src={mbg} className="bg_img" alt="img" />
              </div>
              <div className="choose_tile green">
                <img src={meth2} alt="img" className="icon" />
                <h2>Testing</h2>
                <p>
                  We ensure that all of the projects we manage go through
                  efficient quality tests that follow internationally
                  acknowledged procedures and QA standards, all while being
                  protected by cutting-edge testing technology and tools.
                </p>
                <img src={mbg} className="bg_img" alt="img" />
              </div>
              <div className="choose_tile blue1">
                <img src={meth4} alt="img" className="icon" />
                <h2>Project Initiation</h2>
                <p>
                  One of the foremost stages before beginning any project is
                  acquiring information from the business to understand the
                  project's demands, corporate structure, and targets. Clients
                  may even submit product documentation if necessary, and our
                  analysts can analyse it to correctly estimate time and pricing
                  parameters.
                </p>
                <img src={mbg} className="bg_img" alt="img" />
              </div>
              <div className="choose_tile pink">
                <img src={meth3} alt="img" className="icon" />
                <h2>Create</h2>
                <p>
                  Following the planning stage, the next step is to put the
                  strategy into action to create a revenue-generating website,
                  mobile app, or web application for your company. This will
                  entail several onsite improvements as well as the inclusion of
                  functional designs to ensure the quality of providing you with
                  an efficient platform for your establishment.
                </p>
                <img src={mbg} className="bg_img" alt="img" />
              </div>
              <div className="choose_tile blue2">
                <img src={meth5} alt="img" className="icon" />
                <h2>Deployment</h2>
                <p>
                  It is our responsibility to ensure that orders are delivered
                  to our clients on time, according to their requirements, and
                  with the quality that will eliminate any dangers and questions
                  you may have about your project.
                </p>
                <img src={mbg} className="bg_img" alt="img" />
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
