

export default function NotFound() {
  return (
    <div style={{ padding: '50px 0px' }}>
      <h1>404 Error</h1>
      <h1>Page Not Found</h1>
    </div>
  );
}
