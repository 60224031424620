import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
//import usa from "../../../Assets/img/usa.png";
//import india from "../../../Assets/img/india.png";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

export default function TopSectionLocation() {
  const [data, setdata] = useState([]);
  useEffect(()=>{
    const fetchdata=async()=>{
      await axios.get(`${process.env.REACT_APP_API_URL}/locations`)
      .then(response=>{
        //console.log("locations--->",response);
        setdata(response.data)
      }).catch(error=>{
        //console.log(error);
      })
    }
    fetchdata();
  },[])
  return (
    <>
      <Grid
        container
        spacing={0}
        className="box-container text-center location_title"
      >
        <Grid xs={12}>
          <h2 className="title">Located Around the World</h2>
          <p>
            With 4+ corporate work centers we can manage your business need with
            ease.
          </p>
        </Grid>
      </Grid>
      <Grid container spacing={0} className="box-container m-b-100 location">
      {data.map((loc)=>(
        <Grid xs={12} sm={6} md={3}>
          <div className="location__tiles__content">
            <img src={`https://node.builtdemo.info/${loc.image_path}`} alt="img" className="icons" />
            <div className="location__tiles">
              <h2>{loc.city}</h2>
              <p>
                {loc.address}
                <br />
                &nbsp;
              </p>
            </div>
          </div>
        </Grid>
        ))}
        {/*<Grid xs={12} sm={6} md={3}>
          <div className="location__tiles__content">
            <img src={india} alt="img" className="icons" />
            <div className="location__tiles">
              <h2>Noida</h2>
              <p>
                621, i-Thum Tower-B, Plot No: A- 40, Sector 62, Noida, Uttar
                Pradesh 201301
              </p>
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <div className="location__tiles__content">
            <img src={india} alt="img" className="icons" />
            <div className="location__tiles">
              <h2>Agra</h2>
              <p>
                UG 2A, Bhagwati Plaza, Awas Vikas Coloney Sector 3B, Uttar
                Pradesh 282007
              </p>
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <div className="location__tiles__content">
            <img src={india} alt="img" className="icons" />
            <div className="location__tiles">
              <h2>Jaipur</h2>
              <p>
                Shopping Center shop no 46, First Floor, Subash Nagar, Jaipur,
                Rajasthan 302016
              </p>
            </div>
          </div>
        </Grid>*/}
      </Grid>
    </>
  );
}
