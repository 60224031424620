import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import wad from "../../../Assets/img/wad.png";
import "./index.scss";

export default function WebAppDevelopmentTopSection() {
  return (
    <>
      <section className="web_design">
        <div className="background-overlay"></div>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={6}>
            <div className="wma_img">
              <img src={wad} alt="Web App Development" />
            </div>
          </Grid>

          <Grid xs={12} sm={6}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h2 className="title ">
                  <span className="title-part1">Web App Development</span>
                </h2>
                <p className="mt-30">
                  There are a number of different types of web apps that may be
                  developed by the Webnotic Solutions Technologies. We develop
                  strong and scalable web apps by analysing and researching
                  extensively. As part of our Web App Development, we use a
                  variety of databases and APIs, and we scale and integrate
                  other services. Solutions that optimise business processes and
                  add value to your customers are developed by combining
                  technology stacks efficiently. It is our goal to supply you
                  with solely customer-oriented solutions for your bespoke Web
                  App Development. Any or all of our clients will receive timely
                  assistance and maintenance from us.
                </p>
                <p>
                  E-commerce has radically changed the way people shop and the
                  way they buy products and services. As a result of their
                  hectic schedules, customers choose to purchase on online
                  shopping sites. companies must have an ecommerce website to
                  sell their items to a wide range of customers today. Designers
                  and developers at Webnotics Technologies have delivered
                  world-class e-commerce solutions to clients all over the
                  world. For businesses who want to separate out from the crowd,
                  we provide unique eCommerce web app development services in
                  USA & Germany.
                </p>
                <a
                  href="https://join.skype.com/invite/Ybh1FyV4SeQb"
                  target="_blank"
                  rel="noreferrer"
                  className="c_link"
                >
                  <Button variant="contained" className="btn-theme-colored">
                    <span>get in touch</span>
                  </Button>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
