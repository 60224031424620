import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import "./header.scss";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import axios from "axios";

const TopBar = () => {
  const [fetchdata, setFetchdata] = useState("");
  useEffect(() => {
    const topData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/website-setting`)
        .then((response) => {
          ////console.log("top bar data-->", response)
          setFetchdata(response.data);
        })
        .catch((error) => {
          //console.log(error);
        });
    };
    topData();
  }, []);

  return (
    <div className="topBar">
      <Grid container>
        <Grid item xs={12} sm={8}>
          <ul className="left_content">
            <li>
              <a href="mailto:hello@webnotics.solutions">
                <EmailOutlinedIcon />
                {fetchdata.website_email}
              </a>
            </li>
            <li>
              <AccessTimeIcon /> {fetchdata.days_from} - {fetchdata.days_to} :{" "}
              {fetchdata.opening_time} {fetchdata.opening_time_format} -{" "}
              {fetchdata.closing_time} {fetchdata.closing_time_format}
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ul>
            <li className="right_align">
              <a
                href="https://www.facebook.com/webnoticsofficial"
                aria-label="Facebook Link"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://twitter.com/Webnoticsltd"
                aria-label="Twitter Link"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/webnoticsofficial"
                aria-label="Linkedin Link"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon />
              </a>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
};
export default TopBar;
