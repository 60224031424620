import * as React from "react";
import OpeningTiles from "./OpeningTiles";
import CareerStats from "./Stats";
import TopSectionCareer from "./TopSection";
import LogoCarousel from "../HomeComp/LogoCarousel";
import HomeCta from "../HomeComp/HomeCta";

export default function Career() {
  return (
    <>
      <TopSectionCareer />
      <OpeningTiles />
      <CareerStats />
      <HomeCta />
      <LogoCarousel />
    </>
  );
}
