import React from "react";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ScrollToTop from "../Components/ScrollToTop";
import IosAppDevelopment from "../Components/IosAppDevelopment";
import { Helmet } from "react-helmet";

export default function IosAppDevelopmentPage() {
  return (
    <>
      <Helmet>
        <title>
          iOS App Development Company in Germany | iPhone App Development
          Services
        </title>
        <meta
          name="description"
          content="Get top-quality iPhone app development agency in Germany. Our expert team creates innovative, user-friendly, and high-performance apps to bring your ideas to life and boost your business."
        />
      </Helmet>
      <BreadcrumbMain title="Ios App Development">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Ios App Development
          </Typography>
        </>
      </BreadcrumbMain>
      <IosAppDevelopment />
      <ScrollToTop />
    </>
  );
}
