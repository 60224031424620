import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";

export default function RefundPolicy() {
  return (
    <>
      <Grid container spacing={0} className="box-container RefundPolicy">
        <Grid xs={12}>
          <h2 className="title">Refund & Cancellation Policy</h2>
          <p>Last updated: September 13, 2021</p>
          <p>Thank you for using our services at Webnotics’s Website.</p>
          <p>
            If, for any reason, You are not completely satisfied with a purchase
            We invite You to review our policy on refunds and returns.
          </p>
          <p>
            The following terms are applicable for any products that You
            purchased with Us.
          </p>
          <h2 className="title">Interpretation and Definitions</h2>
          <h2 className="title sub">Interpretation</h2>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h2 className="title sub">Definitions</h2>
          <p>For the purposes of this Return and Refund Policy:</p>
          <p>
            <b>Company</b> (referred to as either “the Company”, “We”, “Us” or
            “Our” in this Agreement) refers to Webnotics Pvt LTD, 621, i-Thum
            Tower-B, Plot No: A- 40, Sector 62,.
            <br />
            <b>Orders</b> mean a request by You to avail services from Us.
            <br />
            <b>Service</b> refers to the digital services.
            <br />
            <b>Website</b> refers to Webnotics’s Website, accessible from
            https://webnotics.solutions
            <br />
            <b>You</b> means the individual accessing or using the Service, or
            the company, or other legal entity on behalf of which such
            individual is accessing or using the Service, as applicable.
          </p>

          <h2 className="title">Your Order Cancellation Rights</h2>
          <p>
            You are entitled to cancel Your Order within 3 days with giving
            validation reason for doing so. The deadline for cancelling an Order
            is 3 days from the date on which You received the product/service.
            or on which a third party you have appointed, who is not the
            carrier, takes possession of the product delivered.
          </p>
          <p>
            In order to exercise Your right of cancellation, You must inform Us
            of your decision by means of a clear statement. You can inform us of
            your decision by:
          </p>
          <p>
            By email: support@webnotics.solutions
            <br />
            We will reimburse You no later than 28 days from the day. We will
            use the same means of payment as You used for the Order, and You
            will not incur any fees for such reimbursement.
          </p>
          <h2 className="title">Conditions for Returns</h2>
          <p>
            In order for the Service to be eligible for a return, please make
            sure that:
          </p>
          <p>
            The Service were purchased in the last 3 days
            <br />
            You must give a written consent that you will be using the service
            availed by any means, mode for next 1 year.
            <br />
            The following cannot be returned:
          </p>
          <p>
            The supply of Service made to Your specifications or clearly
            personalized.
            <br />
            The supply of Service which according to their nature are not
            suitable to be returned, deteriorate rapidly or where the date of
            expiry is over.
            <br />
            The supply of Service which are, after delivery, according to their
            nature, inseparably mixed with other items.
          </p>
          <p>
            We reserve the right to refuse returns of any merchandise that does
            not meet the above return conditions in our sole discretion.
          </p>
          <p>
            Only standard and non personalised services may be refunded.
            Unfortunately, services on sale cannot be refunded. This exclusion
            may not apply to You if it is not permitted by applicable law.
          </p>
          <h2 className="title">Returning Goods</h2>
          <p>
            You are responsible for the cost and risk of refusing the service to
            Us.
          </p>
          <p>Written Consent via offical email Id must be given.</p>
          <h2 className="title">Contact Us</h2>
          <p>
            If you have any questions about our Returns and Refunds Policy,
            please contact us:
          </p>
          <p>By email: support@webnotics.solutions</p>
        </Grid>
      </Grid>
    </>
  );
}
