import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import internationallove from "../../../Assets/img/internationallove.svg";
import Dashboard from "../../../Assets/img/Dashboard.svg";
import MobileApplication from "../../../Assets/img/MobileApplication.svg";
import { Box } from "@mui/material";

export default function WdadCapabilityTiles() {
  return (
    <>
      <div className="WdadCapabilityTiles">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <h2 className="subtitle">
              Web application design and development is the process of creating
              programs that run on a remote server that is accessible over the
              internet.
            </h2>
            <h3 className="title">Web Application Design & Development</h3>
          </Grid>
        </Grid>

        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={12}>
            <Box
              className="WdadCapabilityTiles_sec"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 0,
                m: 0,
              }}
            >
              <div className="choose_tile">
                <div className="icon">
                  <img src={internationallove} alt="img" />
                </div>
                <h2 className="title service-title">Web App Design</h2>
                <p>
                  Web Application – A website is more than just a collection of
                  online pages. An eye-catching website has outstanding design
                  characteristics that set it apart from the competition.
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={Dashboard} alt="img" />
                </div>
                <h2 className="title service-title">
                  Custom Software Development
                </h2>
                <p>
                  High-end business focused on a bespoke solution can achieve
                  success with our tailor-made website your business objectives
                  utilizing appropriate design layout, functionalities &
                  features!!
                </p>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={MobileApplication} alt="img" />
                </div>
                <h2 className="title service-title">
                  Custom CMS Design & Development
                </h2>
                <p>
                  Custom CMS web application creation enables you to
                  effortlessly administer your website via an admin panel. You
                  may update your material more frequently if you don't have a
                  lot of technical skills.
                </p>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
