import LogoCarousel from "../Components/HomeComp/LogoCarousel";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ScrollToTop from "../Components/ScrollToTop";
import Testimonials from "../Components/HomeComp/Testimonials";
import HomeCta from "../Components/HomeComp/HomeCta";
import WhyChooseUs from "../Components/WhyChooseUs";
import Stats from "../Components/AboutUs/Stats";

export default function WhyChooseUsPage() {
  return (
    <>
      <BreadcrumbMain title="Why Choose Us">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Why Choose Us
          </Typography>
        </>
      </BreadcrumbMain>
      <WhyChooseUs />
      <Testimonials />
      <Stats />
      <HomeCta />
      <LogoCarousel />
      <ScrollToTop />
    </>
  );
}
