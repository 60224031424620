// eslint-disable-next-line
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { PopupButton } from "react-calendly";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "./slider.scss";

// import required modules
import { Navigation } from "swiper";
import Typewriter from "typewriter-effect";

const MainSlider = () => {
  return (
    <Swiper
      navigation={true}
      modules={[Navigation]}
      className="mySwiper home_slider"
    >
      <SwiperSlide>
        <div className="slider_content">
          <div className="content">
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString(
                    "Website <span>and</span> applications design agency"
                  )
                  .start();
              }}
            />
            <PopupButton
              url="https://calendly.com/webnotics/30min"
              rootElement={document.getElementById("root")}
              className="btn-theme-colored"
              text={<span>Get in Touch</span>}
            />
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};
export default MainSlider;
