import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import data_management from "../../../Assets/img/DataManagement.png";

export default function SapSection() {
  return (
    <>
      <div className="SapSection">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <img src={data_management} alt="img" />
            <h2 className="title">
              SAP Support Services, Systems, Applications, and Products in Data
              Processing services and solutions tailored to your unique needs.
            </h2>
            <p>
              Webnotics Solutions delivers SAP support services in Germany & USA
              while keeping costs in mind. Because SAP is unquestionably
              mission-critical software, most businesses spend considerable
              expenditures to maintain and operate SAP solutions. Larger firms
              may easily afford a zealous internal SAP maintenance staff;
              nevertheless, it is not a cost-effective value proposition for
              mid-sized businesses. Webnotics Solutions is a reputable and
              well-organized sap Consultant that offers sap support services to
              a variety of small and medium-sized enterprises in a variety of
              sectors. Our SAP talent pool includes extensive domain experience
              as well as technical knowledge to help your organization. We all
              know that every business is unique, and so are their requirements.
              Keeping this in mind, we offer the following support models:
            </p>
            <ul>
              <li>Onsite-offshore support</li>
              <li>Shared offshore assistance</li>
              <li>Dedicated offshore support</li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
