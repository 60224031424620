import BlogItem from "../BlogItem/BlogItem";
import "./index.scss";
import { useEffect, useState } from "react";
import axios from "axios";

const BlogList = ({ page }) => {
  const [fetchdata, setfetchdata] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const recordsPerPage = 9;
  const startIndex = (page - 1) * recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  useEffect(() => {
    const fetchblog = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/blogs`
        );
        //console.log("blog wala h ye response--->", response);
        setfetchdata(response.data);
        setIsLoading(false); // Set isLoading to false when data is fetched
      } catch (error) {
        //console.log("get all blogs error-->", error);
      }
    };
    fetchblog();
  }, []);

  // Render loading indicator or placeholder while data is loading
  if (isLoading) {
    return <p>Loading...</p>;
  }
  //console.log("fetchdata--->", fetchdata);
  return (
    <>
      <ul className="blog-list">
        {fetchdata.slice(startIndex, endIndex).map((el) => (
          <BlogItem item={el} key={el.id} />
        ))}
        {/*<Blog fetchdata={fetchdata} page={page} />*/}
        {/*{fetchdata.map((el) => (
        <BlogItem item={el} key={el.id}/>
      ))}*/}
      </ul>
    </>
  );
};

export default BlogList;
