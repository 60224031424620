import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import webapp from "../../../Assets/img/webapp.png";
import "./index.scss";

export default function WebAppDesignTopSection() {
  return (
    <>
      <section className="web_design">
        <div className="background-overlay"></div>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={6}>
            <div className="wma_img">
              <img src={webapp} alt="Web App Design" />
            </div>
          </Grid>

          <Grid xs={12} sm={6}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h2 className="title ">
                  <span className="title-part1">
                    Web App Designing in USA & Germany
                  </span>
                </h2>
                <p className="mt-30">
                  Web App Design - We take your business in mind while designing
                  web apps, and based on your needs, we will either use pre-made
                  templates or create a unique app. We take the time to learn
                  about your business, your clients, and your unique needs in
                  order to create a website that is not only attractive, but
                  also useful in helping you expand your business. Every stage
                  of your mobile project is covered by Webnotics' web app design
                  services. With App Design's services, you get a fully
                  customized solution. A leading digital business specializing
                  in user interface and user experience design.
                </p>
                <p>
                  In addition to online apps, we also create iOS and Android
                  mobile applications, as well as complex Virtual Stores. Mobile
                  application development is a specialty of App Design, which
                  has a wealth of expertise with a variety of technologies. All
                  of us are well-aware of what it takes to turn a concept into a
                  tangible reality. Because we've worked on a lot of different
                  projects, we know that every application project is unique and
                  deserves specific care. For our clients, we create amazing
                  mobile applications. Strong and dependable solutions are what
                  we offer. In our app development services in the United States
                  and Germany, we employ the most up-to-date and efficient UI /
                  UX.
                </p>

                <a
                  href="https://join.skype.com/invite/Ybh1FyV4SeQb"
                  target="_blank"
                  rel="noreferrer"
                  className="c_link"
                >
                  <Button variant="contained" className="btn-theme-colored">
                    <span>get in touch</span>
                  </Button>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
