import React from "react";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ScrollToTop from "../Components/ScrollToTop";
import OurCapabilities from "../Components/OurCapabilities";

export default function OurCapabilitiesPage() {
  return (
    <>
      <BreadcrumbMain title="Our Capabilities">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Our Capabilities
          </Typography>
        </>
      </BreadcrumbMain>
      <OurCapabilities />

      <ScrollToTop />
    </>
  );
}
