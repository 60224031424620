import React from "react";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import ScrollToTop from "../Components/ScrollToTop";
import { Helmet } from "react-helmet";
import AboutIiot from "../Components/Iiot/AboutIiot";
import IiotTiles from "../Components/Iiot/IiotTiles";
import IiotBottomSection from "../Components/Iiot/IiotBottomSection";
import PartnerCarousel from "../Components/Iiot/PartnerCarousel";
import { PopupButton } from "react-calendly";
import ContactUsForm from "../Components/Contact/ContactUsForm";
import { Grid } from "@mui/material";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

export default function WebnoticsIiotPage() {
  return (
    <>
      <Helmet>
        <title>
          IoT Business Solutions | Industrial IoT Connectivity in Germany
        </title>
        <meta name="description" content="Industrial Iiot" />
      </Helmet>
      <div className="iiot_breadcrumb">
        <BreadcrumbMain title="Revolutionizing The Industry With Expert IoT Solutions in Germany - Your Gateway To A More Connected, Efficient, And Intelligent Industrial Future.">
          <>
            <PopupButton
              url="https://calendly.com/webnotics/30min"
              rootElement={document.getElementById("root")}
              className="btn-theme-colored"
              text={<span>Get in Touch</span>}
            />
          </>
        </BreadcrumbMain>
      </div>
      <AboutIiot />
      <IiotTiles />
      <IiotBottomSection />
      <div className="iiot_form">
        <ContactIiot />
      </div>
      <PartnerCarousel />
      <ScrollToTop />
    </>
  );
}

function ContactIiot() {
  return (
    <div className="contact_us">
      <Grid
        container
        spacing={0}
        className="box-container"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={12} sm={12} md={7} lg={8}>
          <ContactUsForm />
        </Grid>
        <Grid xs={12} sm={12} md={5} lg={4}>
          <div className="contact-icon-box">
            <div className="contact-box-wrapper">
              <div className="contact-wrapper">
                <span className="contact-circled">
                  <LocalPhoneOutlinedIcon />
                </span>
              </div>
              <div className="contact-text">
                <h4 className="contact-box-title ">Phone Number </h4>
                <div className="contact-content">
                  <p>
                    <span>Phone : </span>+1 347-467-1222
                    <br />
                    &nbsp;
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-icon-box">
            <div className="contact-box-wrapper">
              <div className="contact-wrapper">
                <span className="contact-circled">
                  <NearMeOutlinedIcon />
                </span>
              </div>
              <div className="contact-text">
                <h4 className="contact-box-title ">Headquarters</h4>
                <div className="contact-content">
                  <p>
                    <span>Address : </span>131 West 36th Street #1,
                    <br />
                    New York US
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-icon-box">
            <div className="contact-box-wrapper">
              <div className="contact-wrapper">
                <span className="contact-circled">
                  <EmailOutlinedIcon />
                </span>
              </div>
              <div className="contact-text">
                <h4 className="contact-box-title ">Email Address</h4>
                <div className="contact-content">
                  <p>
                    <span>Email : </span>hello@webnotics.solutions <br />
                    &nbsp;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
