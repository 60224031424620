import React from "react";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ScrollToTop from "../Components/ScrollToTop";
import WebsiteDesignDevelopment from "../Components/WebsiteDesignDevelopment";
import { Helmet } from "react-helmet";

export default function WebsiteDesignDevelopmentPage() {
  return (
    <>
      <Helmet>
        <title>
          Website Design & Development | Upwork Web Design Freelancer{" "}
        </title>
        <meta
          name="description"
          content="Website Design & Development - Award Winning Webs Design & Development for all size business. Free Consultation & Quote Mobile Friendly."
        />
      </Helmet>
      <BreadcrumbMain title="Website Design & Development">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Website Design & Development
          </Typography>
        </>
      </BreadcrumbMain>
      <WebsiteDesignDevelopment />
      <ScrollToTop />
    </>
  );
}
