import * as React from "react";
import "./index.scss";
import WdTopSection from "./WdTopSection";
import WdTiles from "./WdTiles";
import LogoCarousel from "../HomeComp/LogoCarousel";
import Stats from "../AboutUs/Stats";
import Testimonials from "../HomeComp/Testimonials";
import Methodology from "./Methodology";
import ChatCta from "../ChatCta";

export default function WebDesign() {
  return (
    <div className="WebDesign">
      <WdTopSection />
      <WdTiles />
      <Stats />
      <Testimonials />
      <Methodology />
      <ChatCta />
      <LogoCarousel />
    </div>
  );
}
