import { useEffect } from "react";
import Portfolio from "../Components/HomeComp/Portfolio";
import ScrollToTop from "../Components/ScrollToTop";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

export default function PortfolioPage() {
  useEffect(() => {
    document.body.classList.add("portfolio");
    return () => {
      document.body.classList.remove("portfolio");
    };
  }, []);
  return (
    <>
      <BreadcrumbMain title="Our Work">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Portfolio
          </Typography>
        </>
      </BreadcrumbMain>
      <Portfolio />
      <ScrollToTop />
    </>
  );
}
