import AboutUs from "../Components/AboutUs";
import LogoCarousel from "../Components/HomeComp/LogoCarousel";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ScrollToTop from "../Components/ScrollToTop";
import Testimonials from "../Components/HomeComp/Testimonials";
import HomeCta from "../Components/HomeComp/HomeCta";

export default function AboutPage() {
  return (
    <>
      <BreadcrumbMain title="About Us">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            About Us
          </Typography>
        </>
      </BreadcrumbMain>
      <AboutUs />
      <Testimonials />
      <HomeCta />
      <LogoCarousel />
      <ScrollToTop />
    </>
  );
}
