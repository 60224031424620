import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import android1 from "../../../Assets/img/android1.svg";
import MobileChat from "../../../Assets/img/MobileChat.svg";
import android2 from "../../../Assets/img/android2.svg";

import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

export default function AndroidAppDevelopmentTopSectionTiles() {
  return (
    <>
      <div className="wdTiles">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <h2 className="subtitle">Android App Development Services</h2>
            <h3 className="title">
              Our Android App Development Services Includes
            </h3>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={12}>
            <Box
              className="wdTiles_sec"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                m: 1,
              }}
            >
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={android1} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">Android NDK</Link>
                      </h4>
                      <div className="tile-details">
                        With the Native Development Kit (NDK), you can run C and
                        C++ programmes on Android.
                      </div>

                      <Link to="/portfolio" className="read-more">
                        <ArrowRightAltIcon /> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={MobileChat} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">Java</Link>
                      </h4>
                      <div className="tile-details">
                        When it comes to creating programmes that can run on
                        cell phones with greater security, Java seems to have
                        been a game-changer.
                      </div>

                      <Link to="/portfolio" className="read-more">
                        <ArrowRightAltIcon /> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose_tile">
                <div className="tile-services">
                  <div className="tile-block">
                    <div className="service-inner">
                      <div className="icon-box">
                        <div className="icon">
                          <img src={android2} alt="img" />
                        </div>
                      </div>
                      <h4 className="title service-title">
                        <Link to="/portfolio">Kotlin</Link>
                      </h4>
                      <div className="tile-details">
                        Kotlin is a new statically constructed programming
                        language that will increase your efficiency and that of
                        your engineers.
                      </div>

                      <Link to="/portfolio" className="read-more">
                        <ArrowRightAltIcon /> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
