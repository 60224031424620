import { Button } from "@mui/material";
import * as React from "react";
import "./index.scss";
import axios from "axios";
import Alert from "@mui/material/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';

export default function ContactUsForm() {
  const navigate = useNavigate(); // Initialize useHistory hook
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [err, setErr] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("subject", subject);
    formData.append("contact", contact);
    formData.append("message", message);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/contact-user`,
        formData
      );
      //console.log("frontend of contact us post-->", response);
      setMsg(response.data.msg);

      // Clear the input fields
      setName("");
      setEmail("");
      setSubject("");
      setContact("");
      setMessage("");
      // Redirect to Hello page
      navigate('/thank-you');
      // Clear the success message after a delay (e.g., 3 seconds)
      // setTimeout(() => {
      //   setMsg("");
      // }, 3000); // Adjust the delay time as needed (in milliseconds)
    } catch (error) {
      //console.log("error contact us-->", error);
      setErr(error.response.data.email[0]);
      //console.log("error contact us-->", error.response.data.email[0]);
      // Handle error
    }
  };
  function onCaptchaChange(value) {
    setIsCaptchaSuccess(true);
    console.log("captcha value: ", value);
  }
  return (
    <div className="ContactUsForm">
      <div className="form">
        <h2>Lets Talk with Us</h2>
        <p>Start working with Landrick that can provide everything</p>
        <form onSubmit={handleSubmit}>
          <div className="form_group">
            <input
              type="text"
              placeholder="Your Name"
              className="form_control"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            <input
              type="email"
              placeholder="Your Email"
              className="form_control"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="form_group">
            <input
              type="text"
              placeholder="Subject"
              className="form_control"
              name="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Phone number"
              className="form_control"
              name="contact"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              required
            />
          </div>
          <div className="form_group">
            <textarea
              placeholder="Write here message"
              className="form_control"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          {err && (
            <Alert severity="error" className="mb_20">
              {err}
            </Alert>
          )}
          <ReCAPTCHA
            sitekey="6LczRFUoAAAAABeQjKRTqS1ApykLkZnjx-PQWnri"
            onChange={onCaptchaChange}
            className="mb_20"
          />
          <Button
            variant="contained"
            type="submit"
            className="mb_20"
            disabled={!isCaptchaSuccessful}
          >
            Submit now
          </Button>

          <div>{msg && <Alert severity="success">{msg}</Alert>}</div>
        </form>
      </div>
    </div>
  );
}
