import Grid from "@mui/material/Grid";
import "./index.scss";
import { Button } from "@mui/material";

export default function HappyCustomersCta() {
  return (
    <>
      <section className="HappyCustomersCta">
        <Grid container spacing={0} className="box-container">
          <Grid xs={12}>
            <div className="wma_content">
              <div className="title-wrapper text-center">
                <h2 className="subtitle ">
                  We cooperate with global brands. But we work closely to gain
                  these golden words.
                </h2>
                <h3 className="title ">
                  <span className="title-part1 ">Happy Customers</span>
                </h3>
                <a
                  href="https://youtu.be/2iBb2NlCwic"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Button
                    variant="contained"
                    className="btn-theme-colored mt-30"
                  >
                    <span>view video now</span>
                  </Button>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
