import * as React from "react";
import LogoCarousel from "../HomeComp/LogoCarousel";
import Stats from "../AboutUs/Stats";
import Testimonials from "../HomeComp/Testimonials";
import ChatCta from "../ChatCta";
import WebAppDesignTopSection from "./WebAppDesignTopSection";
import WebAppDesignTiles from "./WebAppDesignTiles";
import Methodology from "../WebDesign/Methodology";

export default function WebAppDesign() {
  return (
    <div className="WebDesign">
      <WebAppDesignTopSection />
      <WebAppDesignTiles />
      <Stats />
      <Testimonials />
      <Methodology />
      <ChatCta />
      <LogoCarousel />
    </div>
  );
}
