import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import facebook from "../../../Assets/img/s1.png";
import twitter from "../../../Assets/img/s2.png";
import skype from "../../../Assets/img/skype.svg";
import linkedin from "../../../Assets/img/s3.png";
import team1 from "../../../Assets/img/team1.png";
import team2 from "../../../Assets/img/team2.png";

export default function Team() {
  return (
    <>
      <Grid container spacing={0} className="box-container text-center">
        <Grid xs={12}>
          <div className="team">
            <ul>
              <li>
                <div className="team_content">
                  <img src={team1} alt="team" className="img" />
                  <h2>Lalit Yadav</h2>
                  <div className="bottom">
                    <span className="position">Manager & Sr. Analyst</span>
                    <span className="social">
                      <a
                        href="https://www.facebook.com/webnoticsofficial"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={facebook} alt="facebook" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/webnoticsofficial"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={twitter} alt="linkedin" />
                      </a>
                      <a
                        href="https://twitter.com/Webnoticsltd"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedin} alt="twitter" />
                      </a>
                      <a
                        href="https://join.skype.com/invite/Ybh1FyV4SeQb"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={skype} alt="skype" />
                      </a>
                      {/*<Link to="https://www.facebook.com/webnoticsofficial">
                        <img src={facebook} alt="facebook" />
                      </Link>
                      <Link to="https://www.linkedin.com/in/webnotics-private-limited-58754b1a5">
                        <img src={linkedin} alt="linkedin" />
                      </Link>
                      <Link to="https://twitter.com/Webnoticsltd">
                        <img src={twitter} alt="twitter" />
                      </Link>
                      <Link to="#">
                        <img src={skype} alt="skype" />
                      </Link>*/}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div className="team_content">
                  <img src={team2} alt="team" className="img" />
                  <h2>Mrs. Pinki</h2>
                  <div className="bottom">
                    <span className="position">Co-Founder</span>
                    <span className="social">
                      <a
                        href="https://www.facebook.com/webnoticsofficial"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={facebook} alt="facebook" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/webnoticsofficial"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={twitter} alt="linkedin" />
                      </a>
                      <a
                        href="https://twitter.com/Webnoticsltd"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedin} alt="twitter" />
                      </a>
                      <a
                        href="https://join.skype.com/invite/Ybh1FyV4SeQb"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={skype} alt="skype" />
                      </a>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
