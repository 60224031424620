import Grid from "@mui/material/Grid";
import "./index.scss";
import { PopupButton } from "react-calendly";

export default function HomeCta() {
  return (
    <>
      <section className="HomeCta">
        <Grid container spacing={0} className="box-container">
          <Grid xs={12}>
            <div className="wma_content">
              <div className="title-wrapper text-center">
                <h2 className="subtitle ">
                  Effortlessly view, organize, and prioritize all of your
                  projects.
                </h2>
                <h3 className="title ">
                  <span className="title-part1 ">
                    100+ organizations Globally have already been using
                    Webnotics's Services.
                  </span>
                </h3>
                <PopupButton
                  url="https://calendly.com/webnotics/30min"
                  rootElement={document.getElementById("root")}
                  className="btn-theme-colored mt-30"
                  text={<span>Contact with us</span>}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
