import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import insurance from "../../../Assets/img/insurance.svg";
import TimeManagement from "../../../Assets/img/TimeManagement.svg";
import WorkAnalytics from "../../../Assets/img/WorkAnalytics.svg";
import Goal from "../../../Assets/img/Goal.svg";
import CustomerRatingStars from "../../../Assets/img/CustomerRatingStars.svg";

export default function CareerStats() {
  return (
    <>
      <div className="career_stats">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <p className="blue_para">
              We are always on the lookout for talented folk to join our team.
              Follow us on{" "}
              <a
                href="https://www.linkedin.com/company/webnoticsofficial"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn!
              </a>
            </p>
            <h2 className="title">Benefits</h2>
            <p>
              Here are just some of the perks you’ll get as a member of
              Webnotics Solutions.
            </p>
            <div className="stats__box">
              <ul>
                <li>
                  <span className="counts">
                    <img src={insurance} alt="insurance" />
                  </span>
                  <span>Healthy Work Culture</span>
                </li>
                <li className="blue">
                  <span className="counts">
                    <img src={TimeManagement} alt="TimeManagement" />
                  </span>
                  <span>Flexible Work Hours</span>
                </li>
                <li>
                  <span className="counts">
                    <img src={WorkAnalytics} alt="WorkAnalytics" />
                  </span>
                  <span>Career Development</span>
                </li>
                <li>
                  <span className="counts">
                    <img src={Goal} alt="Goal" />
                  </span>
                  <span>Work & Life Balance</span>
                </li>
                <li className="blue">
                  <span className="counts">
                    <img src={CustomerRatingStars} alt="CustomerRatingStars" />
                  </span>
                  <span>Values & Leadership</span>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
