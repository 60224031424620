import * as React from "react";
import "./index.scss";
import LogoCarousel from "../HomeComp/LogoCarousel";
import Stats from "../AboutUs/Stats";
import Testimonials from "../HomeComp/Testimonials";
import Methodology from "../WebDesign/Methodology";
import HappyCustomersCta from "../AboutUs/HappyCustomersCta";
import HomeCta from "../HomeComp/HomeCta";
import OurCapabilityTiles from "./OurCapabilityTiles";
import ImpactIndustries from "./ImpactIndustries";

export default function OurCapabilities() {
  return (
    <div className="WebDesign">
      <OurCapabilityTiles />
      <ImpactIndustries />
      <HappyCustomersCta />
     
      <Stats />
      <Testimonials />
      <Methodology />
      <HomeCta />
      <LogoCarousel />
    </div>
  );
}
