import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import CountUp from "react-countup";

export default function Stats() {
  return (
    <>
      <div className="stats">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <h2 className="title">Experience the difference with Webnotics Solutions</h2>
            <div className="stats__box">
              <ul>
                <li>
                  <span className="counts">
                    {" "}
                    <CountUp start={0} end={13} duration={3} />
                  </span>
                  <span>Years in Business</span>
                </li>
                <li className="blue">
                  <span className="counts">
                    {" "}
                    <CountUp start={0} end={352} duration={4} />
                  </span>
                  <span>Websites Designed</span>
                </li>
                <li>
                  <span className="counts">
                    {" "}
                    <CountUp start={0} end={28} duration={5} />
                  </span>
                  <span>Accolades</span>
                </li>
                <li>
                  <span className="counts">
                    {" "}
                    <CountUp start={0} end={693} duration={6} />
                  </span>
                  <span>Happy Clients</span>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
