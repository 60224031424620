import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import sap1 from "../../../Assets/img/sap1.svg";
import sap2 from "../../../Assets/img/sap2.svg";
import sap3 from "../../../Assets/img/sap3.svg";
import sap4 from "../../../Assets/img/sap4.svg";
import sap5 from "../../../Assets/img/sap5.svg";
import sap6 from "../../../Assets/img/sap6.svg";

import { Box } from "@mui/material";

export default function SapTiles() {
  return (
    <>
      <div className="SapTiles">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <h2 className="subtitle">Solutions</h2>
            <h3 className="title">Our SAP Support Services Includes</h3>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={12}>
            <Box
              className="SapTiles_sec"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 0,
                m: 0,
              }}
            >
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap1} alt="img" />
                </div>
                <h2>Enterprise Automation</h2>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap2} alt="img" />
                </div>
                <h2>Product Lifecycle Management</h2>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap3} alt="img" />
                </div>
                <h2>HCM and Payroll Solutions</h2>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap4} alt="img" />
                </div>
                <h2>S4 HANA Migration</h2>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap5} alt="img" />
                </div>
                <h2>SAP System Support</h2>
              </div>
              <div className="choose_tile">
                <div className="icon">
                  <img src={sap6} alt="img" />
                </div>
                <h2>HCM and Payroll Solutions</h2>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
