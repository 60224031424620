import * as React from "react";
import { Grid } from "@mui/material";
import "./index.scss";
import ContactUs from "../../Assets/img/ContactUs.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { Button } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxWidth: "100%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 0,
  p: 4,
};

export default function ChatCta() {
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [err, setErr] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("subject", subject);
    formData.append("contact", contact);
    formData.append("message", message);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/contact-user`,
        formData
      );
      //console.log("frontend of contact us post-->", response);
      setMsg(response.data.msg);

      // Clear the input fields
      setName("");
      setEmail("");
      setSubject("");
      setContact("");
      setMessage("");

      // Clear the success message after a delay (e.g., 3 seconds)
      setTimeout(() => {
        setMsg("");
      }, 3000); // Adjust the delay time as needed (in milliseconds)
    } catch (error) {
      //console.log("error contact us-->", error);
      setErr(error.response.data.email[0]);
      //console.log("error contact us-->", error.response.data.email[0]);
      // Handle error
    }
  };
  function onCaptchaChange(value) {
    setIsCaptchaSuccess(true);
    console.log("captcha value: ", value);
  }
  return (
    <>
      <div className="ChatCta">
        <Grid
          container
          spacing={0}
          className="box-container tiles"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid xs={12} sm={7}>
            <div className="program_tile">
              <h2>Chat</h2>
              <h3>Connect with our technical expert</h3>
              <p className="text">
                Chat with us today and receive a tailored proposal that fits
                your business needs.
              </p>
              <Grid container spacing={0}>
                <Grid xs={12} sm={12} md={6}>
                  <div className="bottom">
                    <h4 className="email" onClick={handleOpen}>
                      Email
                    </h4>
                    <p>
                      No time to wait around? We usually respond within a few
                      hours
                    </p>
                  </div>
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <div className="bottom">
                    <h4 className="chat">Chat</h4>
                    <p>
                      We're online right now, there are currently 5 people ahead
                      of you
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid xs={12} sm={5}>
            <div className="program_tile text-right">
              <img src={ContactUs} alt="img" />
            </div>
          </Grid>
        </Grid>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="email_modal"
      >
        <Box sx={style}>
          <h2>
            <span className="title-part">
              No time to wait around? We usually respond within a few hours
            </span>
          </h2>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="ContactUsForm">
              <div className="form">
                <form onSubmit={handleSubmit}>
                  <div className="form_group">
                    <input
                      type="text"
                      placeholder="Your Name"
                      className="form_control"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />

                    <input
                      type="email"
                      placeholder="Your Email"
                      className="form_control"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>

                  <div className="form_group">
                    <input
                      type="text"
                      placeholder="Subject"
                      className="form_control"
                      name="subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                    />
                    <input
                      type="number"
                      placeholder="Phone number"
                      className="form_control"
                      name="contact"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form_group">
                    <textarea
                      placeholder="Write here message"
                      className="form_control"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    />
                  </div>
                  {err && (
                    <Alert severity="error" className="mb_20">
                      {err}
                    </Alert>
                  )}
                  <ReCAPTCHA
                    sitekey="6LczRFUoAAAAABeQjKRTqS1ApykLkZnjx-PQWnri"
                    onChange={onCaptchaChange}
                    className="mb_20"
                    disabled={!isCaptchaSuccessful}
                  />
                  <Button variant="contained" type="submit" className="mb_20" disabled={!isCaptchaSuccessful}>
                    Submit now
                  </Button>
                  <div>{msg && <Alert severity="success">{msg}</Alert>}</div>
                </form>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
