import React from "react";
import "./index.scss";
import AboutWebnotics from "./AboutWebnotics";
import InspiringWebsite from "./InspiringWebsite";
import HappyCustomersCta from "./HappyCustomersCta";
import Team from "./Team";
import Stats from "./Stats";

function AboutUs() {
  return (
    <>
      <AboutWebnotics />
      <InspiringWebsite />
      <HappyCustomersCta />
      <Team />
      <Stats />
    </>
  );
}

export default AboutUs;
