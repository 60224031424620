import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import choose2 from "../../../Assets/img/choose2.png";
import choose3 from "../../../Assets/img/choose3.png";
import choose4 from "../../../Assets/img/choose4.png";
import choose5 from "../../../Assets/img/choose5.png";
import choose6 from "../../../Assets/img/choose6.png";
import choose7 from "../../../Assets/img/choose7.png";

import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

export default function ChooseTiles() {
  return (
    <>
      <div className="why_choose_tile">
        <Grid container spacing={0} className="box-container text-center">
          <Grid xs={12}>
            <h2 className="title">You have reasons to work with us!</h2>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="box-container">
          <Grid xs={12} sm={12}>
            <Box
              className="choose_tile_sec"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                m: 1,
              }}
            >
              <div className="choose_tile">
                <img src={choose2} alt="img" className="icon" />
                <h2>200+ Successfully Delivered Projects</h2>
                <p>
                  We are committed to deliver outstanding, cutting edge IT
                  solutions that add real value that goes beyond what is
                  expected.
                </p>
                <Link to="/contact-us">
                  <ArrowRightAltIcon />
                </Link>
              </div>
              <div className="choose_tile">
                <img src={choose3} alt="img" className="icon" />
                <h2>Certified Experts</h2>
                <p>
                  We understand that our people impact the success of our
                  business, and we hire people who are smart, dedicated for your
                  business.
                </p>
                <Link to="/contact-us">
                  <ArrowRightAltIcon />
                </Link>
              </div>
              <div className="choose_tile">
                <img src={choose4} alt="img" className="icon" />
                <h2>10+ Years Of Experience</h2>
                <p>
                  With 10+ years of industry experience we have a stable client
                  tale and wide range of technology stacks.
                </p>
                <Link to="/contact-us">
                  <ArrowRightAltIcon />
                </Link>
              </div>
              <div className="choose_tile">
                <img src={choose5} alt="img" className="icon" />
                <h2>Client Satisfaction</h2>
                <p>
                  Our dedicated team have proven track records for different
                  project delivery and 100% client satisfaction.
                </p>
                <Link to="/contact-us">
                  <ArrowRightAltIcon />
                </Link>
              </div>
              <div className="choose_tile">
                <img src={choose6} alt="img" className="icon" />
                <h2>Clientele Worldwide</h2>
                <p>
                  With our dedicated team efforts we have achieved clientele
                  worldwide.
                </p>
                <Link to="/contact-us">
                  <ArrowRightAltIcon />
                </Link>
              </div>
              <div className="choose_tile">
                <img src={choose7} alt="img" className="icon" />
                <h2>24/7 Online Support</h2>
                <p>
                  We strive to provide superior customer service and ensure that
                  every client is completely satisfied with our work.
                </p>
                <Link to="/contact-us">
                  <ArrowRightAltIcon />
                </Link>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
