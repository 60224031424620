import React from "react";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ScrollToTop from "../Components/ScrollToTop";
import WebApplicationDesignAndDevelopment from "../Components/WebApplicationDesignAndDevelopement";
import { Helmet } from "react-helmet";

export default function WebApplicationDesignAndDevelopementPage() {
  return (
    <>
      <Helmet>
        <title>Web Application Design & Development Company in Germany </title>
        <meta
          name="description"
          content="Web Application design & development services in UK refers to the creation of applications over a remote server that are accessible through."
        />
      </Helmet>
      <BreadcrumbMain title="Web Application Design & Development">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Web Application Design & Development
          </Typography>
        </>
      </BreadcrumbMain>
      <WebApplicationDesignAndDevelopment />
      <ScrollToTop />
    </>
  );
}
