import React from "react";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ScrollToTop from "../Components/ScrollToTop";
import SapSupportAndServices from "../Components/SapSupportAndServices";
import { Helmet } from "react-helmet";

export default function SapSupportAndServicesPage() {
  return (
    <>
      <Helmet>
        <title>
          SAP Support Services in Germany | SAP Maintenance Company in USA
        </title>
        <meta
          name="description"
          content="SAP Support Services in Germany , Systems, Applications, And Products In Data Processing Services And Solutions Tailored To Your Unique Needs."
        />
      </Helmet>
      <BreadcrumbMain title="SAP Support & Services">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            SAP Support & Services
          </Typography>
        </>
      </BreadcrumbMain>
      <SapSupportAndServices />
      <ScrollToTop />
    </>
  );
}
