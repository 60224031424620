import * as React from "react";
import Grid from "@mui/material/Grid";
import "./index.scss";
import Graph from "../../../Assets/img/Graph.svg";
import ph_file from "../../../Assets/img/ph_file.svg";
import ProjectManagement from "../../../Assets/img/ProjectManagement.svg";
import UserNetworking from "../../../Assets/img/UserNetworking.svg";
import mbg from "../../../Assets/img/methodolocy_bg.png";
import seo1 from "../../../Assets/img/seo1.png";
import seo2 from "../../../Assets/img/seo2.png";
import seo3 from "../../../Assets/img/seo3.png";
import { Button } from "@mui/material";

export default function SeoTopSection() {
  return (
    <>
      <div className="SeoTopSection">
        <Grid container spacing={0} className="box-container">
          <Grid xs={12}>
            <h2 className="title">
              Are you looking for a Google-certified SEO Company?
            </h2>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="box-container text_box">
          <Grid xs={12} sm={6}>
            <div className="box">
              <p>
                Although SEO isn't rocket science, it does require time,
                resources, and expertise to develop a successful SEO plan.
                Following an initial consultation and evaluation of your
                website, we'll propose a strategy that includes strategies like:
                <br />
                On-page SEO refers to everything we perform on your website in
                order to increase organic search traffic. We'll be on the
                lookout for a variety of assignments throughout this phase of
                the project, including:
              </p>

              <ul>
                <li>Keyword Research</li>
                <li>Keyword Mapping</li>
                <li>Website Content Optimization</li>
                <li>Meta Tag and Alt Tag Optimization</li>
                <li>Rich Snippets and Structured Data Implementation</li>
                <li>Hyperlinking Optimization</li>
                <li>Mobile Friendly Optimize, etc.</li>
              </ul>
            </div>
          </Grid>
          <Grid xs={12} sm={6}>
            <div className="box">
              <p>
                If you're interested in learning more about our off-page SEO
                services, please contact us. Google, and other search engines,
                take a close look at what people on the web have to say about
                you before ranking your website or listing. One of the most
                significant ranking signals is a high-quality backlink from
                another website to your website. For example, many duties during
                this phase will focus on building as many relevant backlinks to
                your website as possible, including:
              </p>

              <ul>
                <li>Optimizing your Google Business Listing</li>
                <li>Directory Listings</li>
                <li>Social Bookmarking</li>
                <li>Article, Video, Image and Infographic Submission</li>
              </ul>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="box-container tiles_section">
          <Grid xs={12} sm={8}>
            <div className="choose_tile">
              <div className="icon">
                {" "}
                <img src={Graph} alt="img" />
              </div>

              <h2>Rank Tracker</h2>
              <p>
                For some phrases, rank monitoring is used to keep track of where
                URLs appear on the search engine results pages (SERP). As a
                result, the URLs are recorded over time to discover patterns and
                to see the impact of SEO optimization.
              </p>
              <img src={mbg} className="bg_img" alt="img" />
            </div>
            <Grid container spacing={0} className="tiles_section_sec">
              <Grid xs={12} sm={6}>
                <div className="choose_tile green">
                  <div className="icon">
                    {" "}
                    <img src={ProjectManagement} alt="img" />
                  </div>
                  <h2>Website Auditor</h2>
                  <p>
                    Keyword research & ranking sure every step of you SEO
                    Campaign is taken care.
                  </p>
                  <img src={mbg} className="bg_img" alt="img" />
                </div>
              </Grid>
              <Grid xs={12} sm={6}>
                <div className="choose_tile blue">
                  <div className="icon">
                    {" "}
                    <img src={UserNetworking} alt="img" />
                  </div>
                  <h2>Link Assistant</h2>
                  <p>
                    Keyword research & ranking sure every step of you SEO
                    Campaign is taken care.
                  </p>
                  <img src={mbg} className="bg_img" alt="img" />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12} sm={4}>
            <div className="choose_tile min_height purple">
              <div className="icon">
                {" "}
                <img src={ph_file} alt="img" />
              </div>
              <h2>SEO Spyglass</h2>
              <p>
                One of the most important SEO chores – backlink research — may
                be handled effortlessly using SEO SpyGlass. This is all that it
                includes:
              </p>
              <ul>
                <li>Spy on the link-building strategies of your competitors</li>
                <li>Produces eye-catching backlink reports</li>
                <li>The backlinks that bring in the most traffic</li>
                <li>Flexible and adaptable to your needs</li>
                <li>Multi-platform and multi-language software</li>
                <li>Protects you with powerful security features</li>
              </ul>

              <img src={mbg} className="bg_img" alt="img" />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="SeoBottomSection">
        <Grid
          container
          spacing={0}
          className="box-container m-b-70"
          justifyContent="space-between"
        >
          <Grid xs={12} sm={5}>
            <h2 className="title">Search Engine Optimization Software.</h2>
            <p>
              In addition to keywords research and ranking tracking, on-site
              improvements, backlink analysis, and link development are all part
              of a comprehensive Search Engine Optimization campaign.
            </p>
            <Button variant="contained" className="btn-theme-colored">
              <span>Download Free</span>
            </Button>
          </Grid>
          <Grid xs={12} sm={7} className="RightContent">
            <img src={seo1} alt="img" />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          className="box-container m-b-70"
          justifyContent="space-between"
        >
          <Grid xs={12} sm={6}>
            <img src={seo2} alt="img" />
          </Grid>
          <Grid xs={12} sm={5}>
            <h2 className="title">
              Branded Search Engine Optimization Reports.
            </h2>
            <p>
              In addition to keywords research and ranking tracking, on-site
              improvements, backlink analysis, and link development are all part
              of a comprehensive Search Engine Optimization campaign.
            </p>
            <Button variant="contained" className="btn-theme-colored">
              <span>More Details</span>
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          className="box-container m-b-70"
          justifyContent="space-between"
        >
          <Grid xs={12} sm={5}>
            <h2 className="title">
              Uncover technical mistakes that may affect your rankings.
            </h2>
            <p>
              Comprehensivese SEO involves keywords research and ranking
              racking, on-site optimizations, backlink analysis, and link the
              building.
            </p>
            <Button variant="contained" className="btn-theme-colored">
              <span>More Details</span>
            </Button>
          </Grid>
          <Grid xs={12} sm={6} className="RightContent">
            <img src={seo3} alt="img" />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
