import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import project_discuss from "../../../Assets/img/about-two.png";
import "./index.scss";

export default function InspiringWebsite() {
  return (
    <>
      <section className="InspiringWebsite">
        <Grid
          container
          spacing={0}
          className="box-container"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid xs={12} sm={6}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h2 className="subtitle ">Reasonable Prices</h2>
                <h3 className="title ">
                  <span className="title-part1 ">
                    Awe-Inspiring Websites. Reasonable Prices.
                  </span>
                </h3>
                <p className="mt-30">
                  Website and mobile app creation is our forte, since we have
                  many years of expertise. When it comes to working with our
                  clients, we are a team. In this way, we are able to determine
                  client demands and provide the best solution. Each project is
                  overseen by a project manager and a team of professionals.
                </p>
                <p>
                  IT & Software business Webnotics provides end-to-end Websites,
                  WebApplications, and Mobile App Solutions for a wide range of
                  Business Needs. Webnotics was founded with a mission to give
                  startups and small businesses unique web and app solutions
                  utilising the newest technology. Webnotics was founded with
                  the goal of serving exclusively high-end clients. As of right
                  now, Webnotics is composed of individuals with a variety of
                  expertise. A sustainable vision and set of values guide the
                  company's operations, and our services are founded on three
                  principles: love for what we do, commitment to excellence, and
                  honesty.
                </p>

                <Link to="/our-capabilities">
                  <Button variant="contained" className="btn-theme-colored">
                    <span>view all services</span>
                  </Button>
                </Link>
              </div>
            </div>
          </Grid>
          <Grid xs={12} sm={6}>
            <div className="wma_img">
              <img src={project_discuss} alt="Project Discuss" />
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
