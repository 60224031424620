import Grid from "@mui/material/Grid";
import testimonial from "../../../Assets/img/testimonial.png";
import "./index.scss";
import TestimonialsItems from "./TestimonialsItems";

export default function Testimonials() {
  return (
    <>
      <section className="custom_testimonials">
        <div className="background-overlay"></div>
        <Grid
          container
          spacing={0}
          className="box-container"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid xs={12} sm={5}>
            <div className="wma_img">
              <img src={testimonial} alt="web and mobile services" />
            </div>
          </Grid>
          <Grid xs={12} sm={1}></Grid>
          <Grid xs={12} sm={6}>
            <div className="wma_content">
              <div className="title-wrapper">
                <h2 className="subtitle ">Our Testimonials</h2>
                <h3 className="title ">
                  <span className="title-part1 ">What our customers say</span>
                </h3>
              </div>
              <TestimonialsItems />
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
