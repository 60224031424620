import ScrollToTop from "../Components/ScrollToTop";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import Contact from "../Components/Contact";

export default function ContactUs() {
  return (
    <>
      <BreadcrumbMain title="Contact Us">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Contact Us
          </Typography>
        </>
      </BreadcrumbMain>
      <Contact />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <iframe
            width="530"
            height="500"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.111025358608!2d77.37008061502574!3d28.626434391107786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cefc63611bd13%3A0x37c3a4df063fa487!2sWebnotics%20Solutions!5e0!3m2!1sen!2sin!4v1672393818726!5m2!1sen!2sin"
            title="Webnotics Solutions"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            loading="lazy"
            frameBorder="0"
            style={{ width: "100%" }}
          ></iframe>
        </Grid>
        <Grid item xs={12} md={6}>
          <iframe
            width="530"
            height="500"
            title="Lackerbauerstraße"
            style={{ width: "100%" }}
            frameborder="0"
            loading="lazy"
            src="https://www.google.com/maps/embed/v1/place?q=Lackerbauerstraße+House+no.-+3+Munich,+BY+81241+Germany&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          ></iframe>
        </Grid>
      </Grid>

      <ScrollToTop />
    </>
  );
}
