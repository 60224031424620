import { Button } from "@mui/material";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ScrollToTop from "../Components/ScrollToTop";
import { Helmet } from "react-helmet";

export default function ThankYouPage() {
  return (
    <>
      <Helmet>
        <title>Thanks for Contacting Us</title>
        <meta
          name="description"
          content="Thank you for contacting us, we will get back to you as soon as possible."
        />
      </Helmet>
      <BreadcrumbMain title="Thank You">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Thank You
          </Typography>
        </>
      </BreadcrumbMain>
      <div className="thank_you">
        <h2>
          Thank you for contacting us, we will get back to you as soon as
          possible.
        </h2>
        <Link to="/">
          <Button variant="contained" className="btn-theme-colored">
            <span>Go to Home</span>
          </Button>
        </Link>
      </div>

      <ScrollToTop />
    </>
  );
}
