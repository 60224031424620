import React, { useEffect, useState, useMemo } from "react";
import PortfolioItem from "./PortfolioItem";
import { Button } from "@mui/material";
//import { Portfoliolist } from "./data.js";
//import CategoryItem from "./CategoryItem.json";
import { Grid } from "@mui/material";
import "./index.scss";
import axios from "axios";
function Portfolio() {
  const [filter, setFilter] = useState("All");
  const [active, setActive] = useState("active");

  const [fetchcategory, setfetchCategory] = useState([]);
  const [fetchrecord, setfetchRecord] = useState([]);

  const [recordsToShow, setRecordsToShow] = useState(9);
  const [recordsToLoad, setRecordsToLoad] = useState(9);

  useEffect(() => {
    const fetchallcategory = async () => {
      await axios.get(`${process.env.REACT_APP_API_URL}/portfolio-category`)
        .then(response => {
          // //console.log("get all portfolio-category--->", response)
          setfetchCategory(response.data);
        })
        .catch(error => {
          //console.log("get all portfolio-category error-->", error)
        })
    }
    const fetchallrecord = async () => {
      await axios.get(`${process.env.REACT_APP_API_URL}/portfolio`)
        .then(response => {
          // //console.log("get all portfolio--->", response)
          setfetchRecord(response.data);
        })
        .catch(error => {
          //console.log("get all portfolio error-->", error)
        })
    }
    fetchallcategory();
    fetchallrecord();
  }, []);

  //const filteredPortfolio = useMemo(() => {
  //  if (filter === "All") return fetchrecord;

  //  return fetchrecord.filter((portfolio) => portfolio.category === filter);
  //}, [filter, fetchrecord]);

  const filteredPortfolio = useMemo(() => {
    if (filter === "All") return fetchrecord.slice(0, recordsToShow);

    return fetchrecord
      .filter((portfolio) => portfolio.category === filter)
      .slice(0, recordsToShow);
  }, [filter, fetchrecord, recordsToShow]);

  const loadMore = () => {
    setRecordsToShow(recordsToShow + recordsToLoad);
  };
  ////console.log("filter-->",filter)
  return (
    <div className="portfolio_nav">
      <Grid container spacing={0} className="box-container text-center">
        <Grid xs={12}>
          <div className="title-wrapper">
            <h2 className="subtitle ">Portfolio</h2>
            <h3 className="title ">
              <span className="title-part1 ">Our Portfolio</span>
            </h3>
          </div>
        </Grid>
        <Grid xs={12}>
          <ul className="filter_nav">
            {fetchcategory.map((types) => (
              <li
                onClick={() => {
                  setFilter(types.category);
                  setActive(types);
                }}
                key={types.id}
                className={`${active === types ? "active" : ""}`}
              >
                {types.category}
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        className="box-container filter_content m-b-150 sizing"
      >
        {filteredPortfolio.map((item) => (
          <Grid item xs={12} key={item.id} sm={12 / 2} lg={12 / 3} md={12 / 3}>
            {" "}
            <PortfolioItem key={item.id} item={item} />
          </Grid>
        ))}
      </Grid>
      {filteredPortfolio.length < fetchrecord.length && (

        <Button
          variant="contained"
          className="btn-theme-colored mt-30"
          onClick={loadMore}
        >
          <span>Load More</span>
        </Button>
      )}
    </div>
  );
}

export default Portfolio;
