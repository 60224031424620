import { Link } from "react-router-dom";
import "./index.scss";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
//import { useEffect, useState } from "react";
//import axios from "axios";
const BlogItem = (props) => {
  const options = { year: "numeric", month: "long", day: "numeric" };

  //console.log("props blog item me------>", props)
  return (
    <>
      <li className="blog-item">
        <div className="isotope-item-inner">
          <div className="blog-item-current">
            <div className="entry-header">
              <div className="post-thumb">
                <div className="post-thumb-inner">
                  <div className="thumb">
                    <img
                      decoding="async"
                      src={`https://node.builtdemo.info/${props.item.image_path}`}
                      alt="img"
                    />
                    {/*<img decoding="async" src={props.item.image_path} alt="img" />*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="entry-content">
              <ul className="entry-meta">
                <li className=" posted-date">
                  <Link to={"/" + props.item.blog_title_url}>
                    <time className="published updated">
                      {new Date(props.item.publish_date).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </time>
                  </Link>
                </li>
                <li className="list-inline-item author">
                  <AccountCircleIcon />
                  <span className="byline">
                    By
                    <span className="author vcard">
                      <Link to={"/" + props.item.blog_title_url}>
                        {" "}
                        {props.item.blog_author}
                      </Link>
                    </span>
                  </span>
                </li>
              </ul>

              <div className="title-holder">
                <h4 className="entry-title">
                  <Link to={"/" + props.item.blog_title_url}>
                    {props.item.blog_title}
                  </Link>
                </h4>
              </div>
              <div className="btn-view-details post-btn-readmore ">
                <Link to={"/" + props.item.blog_title_url}>
                  Read More <ArrowRightAltIcon />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default BlogItem;
