import ScrollToTop from "../Components/ScrollToTop";
import { BreadcrumbMain } from "../Components/Breadcrumb";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import PrivacyPolicy from "../Components/PrivacyPolicy";

export default function PrivacyPolicyPage() {
  return (
    <>
      <BreadcrumbMain title="Privacy Policy">
        <>
          <Link underline="hover" key="1" color="inherit" href="#">
            Home
          </Link>
          <span>&#10140;</span>
          <Typography key="2" color="text.primary">
            Privacy Policy
          </Typography>
        </>
      </BreadcrumbMain>
      <PrivacyPolicy />
      <ScrollToTop />
    </>
  );
}
