import { useEffect } from "react";
import MainSlider from "../Components/MainSlider";
import ScrollToTop from "../Components/ScrollToTop";
import WMAService from "../Components/HomeComp/WMAServices";
import FullRangeServices from "../Components/HomeComp/FullRangeServices";
import LogoCarousel from "../Components/HomeComp/LogoCarousel";
import AboutUs from "../Components/HomeComp/AboutUs";
import Testimonials from "../Components/HomeComp/Testimonials";
import HomeHelp from "../Components/HomeComp/HomeHelp";
import HomeCta from "../Components/HomeComp/HomeCta";
import ProjectDiscuss from "../Components/HomeComp/ProjectDiscuss";
import Portfolio from "../Components/HomeComp/Portfolio";
import { Helmet } from "react-helmet";

export default function HomePage() {
  useEffect(() => {
    document.body.classList.add("home");
    return () => {
      document.body.classList.remove("home");
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Webnotics Solution | Website, Digital Marketing & Software Services
        </title>
        <meta
          name="description"
          content="Webnotics is an IT & Software business that provides end-to-end solutions for websites, online applications, mobile apps, digital marketing seo services.."
        />
      </Helmet>
      <MainSlider />
      <LogoCarousel />
      <WMAService />
      <FullRangeServices />
      <AboutUs />
      <Testimonials />
      <Portfolio />
      <HomeHelp />
      <HomeCta />
      <ProjectDiscuss />
      <ScrollToTop />
    </>
  );
}
